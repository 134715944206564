import { PageEvent } from "@angular/material/paginator";

export class TemplateMensagemPesquisaModel {

    constructor() {
    }

    transportadorIdList: string[] = [];
    status: string = 'T';
    nome: string;
    mensagem: string;
    page: number = 0;
    size: number = 10;
}