import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParentService } from 'src/app/core/service/parent.service';
import { environment } from 'src/environments/environment';
import { RotaModel } from './rota-model';
import { RotaPesquisaModel } from 'src/app/core/model/rota-pesquisa-model';

@Injectable({
  providedIn: 'root'
})
export class RotaService extends ParentService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  salvar(rota: RotaModel){
    const url = `${environment.gerenciadorRisco.rota.salvar}`;
    return super.post(url, rota);
  }

  listar(pesquisa:RotaPesquisaModel): Observable<any> {
    const params = new HttpParams({fromObject: pesquisa as any});
    const url = `${environment.gerenciadorRisco.rota.listarRotas}`;
    return super.get(url, params);
  }

  verificarRotaVeiculo(rotaId: string, lat: number, lng: number): Observable<any> {
    const url = `${environment.gerenciadorRisco.rota.verificarRotaVeiculo}?rotaId=${rotaId}&lat=${lat}&lng=${lng}`;
    return super.get(url);
  }


  buscarRota(nomeRota: string, clienteTransportadorId: string) {
    const encodedNomeRota = encodeURIComponent(nomeRota);
    const url = `${environment.gerenciadorRisco.rota.buscarRota}?nomeRota=${encodedNomeRota}&clienteTransportadorId=${clienteTransportadorId}`;
    return super.get(url);
}

  buscarRotaPorId(_id: string) {
    const url = `${environment.gerenciadorRisco.rota.buscarRotaPorId}/${_id}`;
    return super.get(url);
  }

  alterarStatusRota(_id: string, ativo: boolean){
    let params = new HttpParams();
    params = params.set('ativo', ativo);
    const url = `${environment.gerenciadorRisco.rota.alterarStatusRota}/${_id}`;
    return super.put(url, params);
  }

  salvarRotaAlternativa(rotaAlternativa: RotaModel, monitoramentoId: string){
    const url = `${environment.acoes.monitoramento.gravarRotaAlternativa}/${monitoramentoId}`;
    return super.post(url, rotaAlternativa);
  }

  removerRotaAlternativa(monitoramentoId: string){
    const url = `${environment.acoes.monitoramento.excluirRotaAlternativa}/${monitoramentoId}`;
    return super.delete(url);
  }
}
