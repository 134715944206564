<h1 mat-dialog-title>Novo grupo de notificação</h1>
<mat-dialog-content style="margin-top: 1em;" [formGroup]="formGroup">
   <mat-card>
      <mat-card-content>
         <div class="grid">
            <div class="col-12">
               <mat-form-field class="form_monitoramento" appearance="outline">
                  <mat-label>Tipo de notificação</mat-label>
                  <mat-select formControlName="tipoNotificacao" (selectionChange)="tipoNotificacaoChanged($event.value)">
                     <mat-option [value]="'Grupo'">
                       Notificar um grupo
                     </mat-option>
                     <mat-option [value]="'Motorista'">
                       Notificar o motorista
                     </mat-option>
                   </mat-select>
                   <mat-error>Campo obrigatório</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
               <mat-form-field class="form_monitoramento" appearance="outline">
                  <mat-label>Grupo</mat-label>
                  <input formControlName="grupoContato" matInput [matAutocomplete]="auto4">
                  <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFnGrupoContato">
                    <mat-option *ngFor="let grupo of filteredGrupoContato$ | async" [value]="grupo">
                      {{grupo.nome}}
                    </mat-option>
                    <mat-option *ngIf="(filteredGrupoContato$ | async)?.length === 0">
                      Nenhum grupo encontrado
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>Selecione o grupo!</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
               <mat-form-field class="form_monitoramento" appearance="outline">
                  <mat-label>Template</mat-label>
                  <input formControlName="templateMensagem" matInput [matAutocomplete]="auto5">
                  <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFnTemplate">
                    <mat-option *ngFor="let template of filteredTemplate$ | async" [value]="template">
                      {{template.nome}}
                    </mat-option>
                    <mat-option *ngIf="(filteredTemplate$ | async)?.length === 0">
                      Nenhum template encontrado
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>Selecione o template!</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
               <mat-form-field appearance="outline">
                  <mat-label>Nº de alertas entre disparos:</mat-label>
                  <input matInput type="number" formControlName="numeroAlertasParaNotificar">
                  <mat-error>Campo obrigatório</mat-error>
               </mat-form-field>
            </div>
         </div>
      </mat-card-content>
   </mat-card>
</mat-dialog-content>
<div mat-dialog-actions>
   <button mat-button (click)="cancelar()">Cancelar</button>
   <button mat-button cdkFocusInitial (click)="okBtnClick()">Ok</button>
</div>