<google-map [options]="options" id="map" [zoom]="this.zoom" class="my-map">

  <map-marker-clusterer [imagePath]="markerClustererImagePath" [minimumClusterSize]="5" [maxZoom]="18" *ngIf="mapMarkerCluster == true;else noClusterTemplate">
    <map-marker
      #markerElem="mapMarker" *ngFor="let markerPosition of markerPositions;let i = index"
      [position]="{lat:markerPosition.lat, lng:markerPosition.lng}"
      [options]="markerPosition.markerOptions ? markerPosition.markerOptions : this.markerOptions"
      (mapClick)="openInfo(markerElem, markerPosition.dataObject)"
      [title]="''+i">
    </map-marker>
  </map-marker-clusterer>

  <ng-template #noClusterTemplate>
    <map-marker
      #markerElem="mapMarker" *ngFor="let markerPosition of markerPositions;let i = index"
      [position]="{lat:markerPosition.lat, lng:markerPosition.lng}"
      [options]="markerPosition.markerOptions ? markerPosition.markerOptions : this.markerOptions"
      (mapClick)="openInfo(markerElem, markerPosition.dataObject)"
      [title]="''+i">
    </map-marker>
  </ng-template>

  <map-info-window>
    <p style="text-align: center;"><b>Dados do Veículo:</b></p>
    <p><b>Placa:</b> {{dadosMarker.placa}}</p>
    <p><b>Velocidade:</b> {{dadosMarker.velocidade}} km/h</p>
    <p><b>Ignição:</b> {{dadosMarker.ign == false ? 'Desligado' : 'Ligado'}}</p>
    <p><b>Latitude:</b> {{dadosMarker.lat}}</p>
    <p><b>Longitude:</b> {{dadosMarker.lng}}</p>
  </map-info-window>

  <map-info-window #geometryInfoWindow>
    <p style="text-align: center;"><b>Dados da Geometria:</b></p>
    <p><b>Placa:</b> {{dadosMarker.placa}}</p>
    <p><b>Velocidade:</b> {{dadosMarker.velocidade}} km/h</p>
    <p><b>Ignição:</b> {{dadosMarker.ign == false ? 'Desligado' : 'Ligado'}}</p>
  </map-info-window>

  <map-polygon *ngFor="let polygon of polygonList" [paths]="polygon.vertices" [options]="polygon.polygonOptions"
    (polygonRightclick)="polygonRightclick(polygon)">
  </map-polygon>

  <map-circle *ngFor="let circle of circleList" [center]="circle.center" [radius]="circle.radius" [options]="circle.options"
    (circleRightclick)="circleRightclick(circle)">
  </map-circle>

  <map-polyline *ngFor="let item of verticesList" [path]="item.vertices" [options]="item.polylineOptions"
    (polylineRightclick)="polylineRightclick(item)">
  </map-polyline>

  <!-- <map-polygon *ngIf="polygon.length > 0" #roofArea [paths]="polygon" [options]="options"></map-polygon> -->
  <!-- <map-directions-renderer *ngIf="(directionsResults$ | async) as directionsResults"
  [options]="directionsRendererOptions" [directions]="directionsResults" ></map-directions-renderer> -->
  <map-directions-renderer
    *ngIf="(directionsResults$ | async) as directionsResults"
    [options]="directionsRendererOptions"
    [directions]="directionsResults"
    (directionsChanged)="setDirectionsOnDrag($event)">
  </map-directions-renderer>

</google-map>
