import { PermissaoService } from './../service/permissao.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appHasWritePermission]'
})
export class HasWritePermissionDirective {

  @Input() set appHasWritePermission(urlFuncionalidade: string) {
    this.updateView(urlFuncionalidade);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissaoService: PermissaoService
  ) {}

  private updateView(urlFuncionalidade: string): void {
    this.viewContainer.clear();
    if (this.permissaoService.hasWritePermission(urlFuncionalidade)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
