import { Component, Inject } from '@angular/core';
import { GrupoContatoTemplateFormComponent } from '../grupo-contato-template-form/grupo-contato-template-form.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificacaoMensagemModel } from '../../model/notificacao-mensagem-model';
import { MatTableDataSource } from '@angular/material/table';
import { TipoNotificacaoEnum } from '../../enum/tipo-notificacao-enum';

@Component({
  selector: 'app-grupo-contato-template',
  templateUrl: './grupo-contato-template.component.html',
  styleUrls: ['./grupo-contato-template.component.scss']
})
export class GrupoContatoTemplateComponent {

  displayedColumns: string[] = ['tipoNotificacao', 'nomeGrupoContato', 'nomeTemplate', 'numeroAlertasParaNotificar', 'acoes'];
  dataSource: MatTableDataSource<NotificacaoMensagemModel> = new MatTableDataSource();

  tipoNotificacaoMotorista: string = TipoNotificacaoEnum.M;
  tipoNotificacaoGrupo: string = TipoNotificacaoEnum.G;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GrupoContatoTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      notificacoesList: NotificacaoMensagemModel[]
    }
  ) {
    this.dataSource.data = Object.assign([], this.data.notificacoesList);
  }
  
  adicionar(notificacaoMensagemModel?: NotificacaoMensagemModel, index = -1): void {
     
    const dialogRef = this.dialog.open(GrupoContatoTemplateFormComponent, {
      minWidth: '20%',
      minHeight: '50%',
      data: {notificacaoMensagemModel}
    });

    dialogRef.afterClosed().subscribe((newNotificacaoMensagemModel: NotificacaoMensagemModel) => {
      const dataSource = this.dataSource.data;
      if(index != -1) {
        dataSource[index] = newNotificacaoMensagemModel;
      } else {
        if(newNotificacaoMensagemModel) {
          dataSource.push(newNotificacaoMensagemModel);
        }
      }
      this.dataSource.data = dataSource;
    });
  }

  remover(index: number) {
    const dataSource = this.dataSource.data;
    dataSource.splice(index, 1);
    this.dataSource.data = dataSource;
  }

  sortData(event: any) {
  }

  cancelar() {
    this.dialogRef.close();
  }

  okClick() {
    this.dialogRef.close(this.dataSource.data);
  }
}
