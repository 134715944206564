import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../service/snackbar.service';
import { PGRService } from 'src/app/private/pgr/pgr.service';
import { EventoPGRModel } from '../../model/evento-pgr-model';

@Component({
  selector: 'app-configuracoes-eventos-inibidos',
  templateUrl: './configuracoes-eventos-inibidos.component.html',
  styleUrls: ['./configuracoes-eventos-inibidos.component.scss']
})
export class ConfiguracoesEventosInibidosComponent {
  eventoList: EventoPGRModel[] = [];
  eventosInibidosIdsOrigem: string[] = [];
  raioMetros: number;

  constructor(
    private dialogRef: MatDialogRef<ConfiguracoesEventosInibidosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private PGRService: PGRService,
    private snackbarService: SnackbarService
  ) {
    this.buscarEventos();
    this.raioMetros = this.data?.raioMetros || null;
    this.eventosInibidosIdsOrigem = this.data?.eventosInibidosIdsOrigem || [];

  }

  buscarEventos(): void {
    this.PGRService.listarEventosPGR().subscribe({
      next: (eventos: EventoPGRModel[]) => {
        this.eventoList = eventos;
        if (eventos.length === 0) {
          this.snackbarService.openSnackBar('Nenhum evento encontrado', 'Fechar', 'info-snackbar');
        }
      },
      error: (err) => {
        console.error('Erro ao buscar eventos:', err);
        this.snackbarService.openSnackBar('Erro ao buscar eventos', 'Fechar', 'error-snackbar');
      }
    });
  }

  okBtnClick(): void {
    if (!this.eventosInibidosIdsOrigem || this.eventosInibidosIdsOrigem.length === 0) {
      this.snackbarService.openSnackBar('Selecione pelo menos um evento.', 'Fechar', 'warn-snackbar');
      return;
    }

    if (!this.raioMetros) {
      this.snackbarService.openSnackBar('Defina o raio em metros.', 'Fechar', 'warn-snackbar');
      return;
    }

    this.dialogRef.close({
      eventosInibidosIdsOrigem: this.eventosInibidosIdsOrigem,
      raioMetros: this.raioMetros
    });
  }

  cancelBtnClick(): void {
    this.dialogRef.close(null);
  }

}
