import { Injectable } from '@angular/core';
import { UsuarioModel } from '../model/usuario-model';
import { ClienteTransportadorService } from 'src/app/private/cliente-transportador/cliente-transportador.service';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {
  private _storage: Storage | null = null;

  private readonly ECOTECHUSER: string = '$ECOTECHUSER';

  usuarioModel: UsuarioModel;

  constructor(private transportadorService: ClienteTransportadorService) {
  }

  getUsuarioData() {
    if(!this.usuarioModel || !this.usuarioModel.token) {
      const usuarioSalvo = localStorage.getItem(this.ECOTECHUSER);
      this.usuarioModel = new UsuarioModel();
      if(usuarioSalvo) {
        Object.assign(this.usuarioModel, JSON.parse(usuarioSalvo));
      } else {
        this.usuarioModel = new UsuarioModel();
      }
    }
    return this.usuarioModel;
  }

  setUsuarioDataStorage(usuario: UsuarioModel): void {
    Object.assign(this.usuarioModel, usuario);
    localStorage.setItem(this.ECOTECHUSER, JSON.stringify(usuario));
  }

  userIsLogged(): boolean {
    const usuario: UsuarioModel = this.usuarioModel;

    if(!usuario || !usuario.token || usuario.token.trim().length == 0) {
      this.clearStorage();
      return false;
    }

    return true;
  }


  clearStorage() {
    localStorage.removeItem(this.ECOTECHUSER);
  }

  clearCaches() {
    this.transportadorService.refreshCacheTodosTransportadoresList();
  }
}
