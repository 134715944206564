import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParentService {

  readonly baseUrl: string = environment.hostMonitoramentoURL;

  constructor(private http: HttpClient) { }

  get(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }, responseType?: any): Observable<any> {
    const href: string = `${this.baseUrl}/${requestUrl}`;
    return this.http.get<any>(href, { params: params, responseType });
  }

  post(requestUrl: string, data: any): Observable<any> {
    const href: string = `${this.baseUrl}/${requestUrl}`;
    return this.http.post<any>(href, data);
  }

  put(requestUrl: string, data: any): Observable<any> {
    const href: string = `${this.baseUrl}/${requestUrl}`;
    return this.http.put<any>(href, data);
  }

  delete(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }): Observable<any> {
    const href: string = `${this.baseUrl}/${requestUrl}`;
    return this.http.delete<any>(href, { params: params });
  }
}
