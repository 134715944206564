import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MonitoramentoModel } from 'src/app/core/model/monitoramento-model';
import { ParentService } from 'src/app/core/service/parent.service';
import { environment } from 'src/environments/environment';
import { MonitoramentoFiltro } from './monitoramento-filtro';
import { EstacaoModel } from 'src/app/core/model/estacao-model';

@Injectable({
  providedIn: 'root'
})
export class MonitoramentoService extends ParentService {

  private placaSource = new BehaviorSubject<string>('');
  currentPlaca = this.placaSource.asObservable();

  atualizaCardsMonitoramento = new EventEmitter<{ placa: string, tecnologiasRastreioSelecionadas: string[], estacoesSelecionadas: string[], transportadoraFilialIdList?: string[], eventosSelecionados?:string[], prioridadesSelecionadas?: number[] }>();

  emitAtualizaCardsMonitoramento(placa: string, tecnologiasRastreioSelecionadas: string[], estacoesSelecionadas: string[], transportadoraFilialIdList?: string[], eventosSelecionados?:string[], prioridadesSelecionadas?: number[] ) {
    const payload = { placa, tecnologiasRastreioSelecionadas, estacoesSelecionadas, transportadoraFilialIdList, eventosSelecionados, prioridadesSelecionadas};
    this.atualizaCardsMonitoramento.emit(payload);
  }

  changePlaca(placa: string) {
    this.placaSource.next(placa);
  }

  constructor(httpClient: HttpClient) {
    super(httpClient);
   }

  buscarTodosMonitoramentos(filtro: MonitoramentoFiltro,page: number = 0, size: number) {
    let params = new HttpParams({ fromObject: { transportadorIdList: filtro.transportadorIdList } });

    if (filtro.status) {
      params = params.append('status', filtro.status);
    }
    if (filtro.placa) {
      params = params.append('placa', filtro.placa);
    }
    if (filtro.numeroSM) {
      params = params.append('numeroSM', filtro.numeroSM);
    }

    const appendDate = (paramName: string, date?: Date) => {
      if (date) {
          params = params.append(paramName, date.toISOString().replace('T00:00:00.000Z', ''));
      }
    }

    appendDate('dataInicioPrevistaStart', filtro.dataInicioPrevistaStart);
    appendDate('dataInicioPrevistaEnd', filtro.dataInicioPrevistaEnd);
    appendDate('dataCadastroStart', filtro.dataCadastroStart);
    appendDate('dataCadastroEnd', filtro.dataCadastroEnd);

    const url = `${environment.cadastro.monitoramento.getMonitoramentos}?page=${page}&size=${size}`;
    return super.get(url, params);
  }

  buscarRastreiosAtivos(page: number = 0, size: number, placa?: string, transportadoraFilialIdList?:string[], tecnologiasRastreio?: string[], estacoes?: string[], eventos?: string[], prioridades?: number[]) {
    let params = new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString());

    if (placa) {
      params = params.set('placa', placa);
    }

    if (transportadoraFilialIdList && transportadoraFilialIdList.length) {
      transportadoraFilialIdList?.forEach(transportadoraFilialIdList => {
        params = params.append('transportadoraFilialIdList', transportadoraFilialIdList);
      });
    }

    if (tecnologiasRastreio && tecnologiasRastreio.length) {
      tecnologiasRastreio?.forEach(tecnologia => {
        params = params.append('tecnologiaRastreio', tecnologia);
      });
    }

    if(estacoes && estacoes.length){
      estacoes.forEach(estacao => {
        params = params.append('estacao', estacao);
      });
    }

    if(eventos && eventos.length){
      eventos.forEach(evento => {
        params = params.append('evento', evento);
      });
    }

    if(prioridades && prioridades.length){
      prioridades.forEach(prioridade => {
        params = params.append('prioridade', prioridade);
      });
    }

    const url = `${environment.acoes.monitorar.getRastreiosAtivos}`;
    return super.get(url, params);
  }

  buscarRastreiosPorEstacao(listaEstacoes: EstacaoModel[]){

    const estacoesQueryString = listaEstacoes.map(estacao => `estacao=${estacao._id}`).join('&');

    const url = `${environment.gerenciadorRisco.rastreio.listarRastreioPorEstacao}?${estacoesQueryString}`;
    return super.get(url);
  }

  gravarMonitoramento(monitoramento: MonitoramentoModel){
    const url = `${environment.cadastro.monitoramento.postNovoMonitoramento}`;
    return super.post(url, monitoramento);
  }

  atualizarRastreiosAtivos(): Observable<any>{
    const url = `${environment.acoes.monitoramento.atualizarRastreios}`;
    return super.get(url);
  }

  aprovarMonitoramentoVeiculo(idMonitoramento: String){
    const url = `${environment.acoes.monitoramento.aprovarMonitoramento}`;
    return super.put(url, idMonitoramento);
  }

  cancelarMonitoramentoVeiculo(idMonitoramento: string){
    const url = `${environment.acoes.monitoramento.cancelarMonitoramento}`;
    return super.put(url, idMonitoramento);
  }

  reprovarMonitoramentoVeiculo(idMonitoramento: string){
    const url = `${environment.acoes.monitoramento.reprovarMonitoramento}`;
    return super.put(url, idMonitoramento);
  }

  autorizarVeiculo(idMonitoramento: string){
    const url = `${environment.acoes.monitoramento.autorizarVeiculo}`;
    return super.post(url, idMonitoramento);
  }

  buscaHistoricoRastreioPorMonitoramento(monitoramentoId: string, page: number = 0, size: number = 10){
    const url = `${environment.acoes.logMonitoramento.getLogMonitoramento}/${monitoramentoId}?page=${page}&size=${size}`;
    return super.get(url);
  }

  finalizarRastreio(idMonitoramento: string){
    const url = `${environment.acoes.monitoramento.finalizarRastreio}`;
    return super.post(url, idMonitoramento);
  }

  buscarViagensFinalizadas(page: number = 0, size: number, placa?: string){
    let params = new HttpParams()
    .set('page', page.toString())
    .set('size', size.toString());

  if (placa) {
    params = params.set('placa', placa);
  }
    const url = `${environment.acoes.monitoramento.getViagensFinalizadas}?page=${page}&size=${size}`;
    return super.get(url, params);
  }
  buscarHistoricoRota(monitoramentoId: string) {
    const url = `${environment.acoes.monitoramento.getRotas}/${monitoramentoId}`;
    return super.get(url);
  }

  recuperaMonitoramentoPorId(monitoramentoId: string){
    const url = `${environment.cadastro.monitoramento.getMonitoramentoById}/${monitoramentoId}`;
    return super.get(url);
  }

  totalizadoresMonitoramento(filter: any){
    let params = new HttpParams();

    if (filter.placa) {
      params = params.set('placa', filter.placa);
    }
    if (filter.tecnologiasRastreioSelecionadas && filter.tecnologiasRastreioSelecionadas.length > 0) {
        params = params.set('tecnologiaRastreio', filter.tecnologiasRastreioSelecionadas.join(','));
    }
    if (filter.estacoesSelecionadas && filter.estacoesSelecionadas.length > 0) {
        params = params.set('estacao', filter.estacoesSelecionadas.join(','));
    }
    if (filter.eventosSelecionados && filter.eventosSelecionados.length > 0) {
        params = params.set('evento', filter.eventosSelecionados.join(','));
    }
    if (filter.prioridadesSelecionadas && filter.prioridadesSelecionadas.length > 0) {
        params = params.set('prioridade', filter.prioridadesSelecionadas.join(','));
    }
    if (filter.transportadoraFilialIdList && filter.transportadoraFilialIdList.length > 0) {
        params = params.set('transportadoraFilialIdList', filter.transportadoraFilialIdList.join(','));
    }
    const url = `${environment.acoes.monitorar.getTotalizadores}`;
    return super.get(url, params);
  }

  verificarIntegracaoTecnologiaVeiculo(placa: string, tecnologiaRastreio: string, clienteTransportadorId: string) {
    const url = `${environment.acoes.monitorar.verificarVeiculoAtivo}`;
    return super.get(url);
  }

  reativarSM(idMonitoramento: string){
   const url = `${environment.acoes.monitoramento.reativarSM}`;
    return super.put(url, idMonitoramento);
  }
}
