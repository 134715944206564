import { Injectable } from '@angular/core';
import { ParentService } from '../../service/parent.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObservacaoModel } from './observacao-model';

@Injectable({
  providedIn: 'root'
})
export class ObservacoesSmService extends ParentService  {

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }
  
  listar(page: number = 0, size: number, filtros?: HttpParams): Observable<any> {
    return super.get(`${environment.cadastro.monitoramento.listarObservacoes}?page=${page}&size=${size}`, filtros);
  }
  
  gravar(observacao: ObservacaoModel): Observable<any> {
    return super.post(`${environment.cadastro.monitoramento.salvarObservacao}`, observacao);
  }
}
