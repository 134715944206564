import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PontoParadaRota, RotaModel } from '../rota-model';
import { RotaService } from '../rota.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/service/snackbar.service';
import { AppGoogleMapsComponent } from 'src/app/core/component/app-google-maps/app-google-maps.component';
import { LoadingService } from 'src/app/core/service/loading.service';
import { IPolyline } from 'src/app/core/component/app-google-maps/ipolyline';
import { GmapsAutocompleteResult } from 'src/app/core/model/gmaps-autocomplete-result';
import { MatSelectChange } from '@angular/material/select';
import { ClienteTransportadorService } from '../../cliente-transportador/cliente-transportador.service';
import { ClienteTransportadorModel } from 'src/app/core/model/cliente-transportador-model';
import { DadosEnderecoModel } from 'src/app/core/model/dados-endereco-model';

@Component({
  selector: 'app-rota-form',
  templateUrl: './rota-form.component.html',
  styleUrls: ['./rota-form.component.scss']
})
export class RotaFormComponent implements OnInit, AfterViewInit{

  @ViewChild(AppGoogleMapsComponent) gmapsCmp: AppGoogleMapsComponent;
  loading: boolean = false;
  desabilitarGravar: boolean = false;
  rotaAlternativa: boolean = false;
  @Input() rota: RotaModel = new RotaModel();
  form: FormGroup;
  routeResponse: google.maps.DirectionsResult | null;
  waypoints: google.maps.DirectionsWaypoint[] = [];
  editing: boolean = false;
  duplicar: boolean = false;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  polylineList: IPolyline [] = [];
  readonly MARKERS: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  clientesTransportador: ClienteTransportadorModel[];

  constructor(
    private fb: FormBuilder,
    private rotaService: RotaService,
    private dialogRef: DialogRef,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: {
      rota: RotaModel,
      rotaRealizada: {vertices: google.maps.LatLngLiteral[], polylineOptions: google.maps.PolylineOptions},
      duplicar?: boolean
    },
    private loadingService: LoadingService,
    private clienteTransportadorService: ClienteTransportadorService
  ) {

    this.rota = Object.assign(new RotaModel(), this.data.rota);
    this.rotaAlternativa = this.data.rota.rotaAlternativa ? true : false;
    this.duplicar = this.data.duplicar;

    if(this.rota._id && !this.duplicar) {
      this.editing = true;
    }

    if(this.duplicar) {
      this.rota._id = null;
      this.rota.nome = null;
    }
  }

  ngOnInit(): void {
    this.buscarTransportadorasMatrizesCadastradas();

    this.form = this.fb.group({
      '_id': new FormControl(this.rota._id, []),
      'descricao': new FormControl(this.rota.descricao, [Validators.required]),
      'distancia': new FormControl(this.rota.distancia, []),
      'nome': new FormControl(this.rota.nome, []),
      'origem': new FormControl(this.rota.origem, [Validators.required]),
      'destino': new FormControl(this.rota.destino, [Validators.required]),
      'pontoParadaRota': new FormControl(this.rota.pontoParadaRota, [Validators.required]),
      'clienteGerenciadorId': new FormControl(this.rota.clienteGerenciadorId),
      'clienteTransportadorId': new FormControl(this.rota.clienteTransportadorId, [Validators.required])
    });
    this.form.get('nome').disable();
  }

  ngAfterViewInit(): void {
    if(this.rota.rotaAlternativa) {
      this.gerarRota();
      this.form.disable();
    }
    else if(this.editing) {
      this.form.disable();
      this.desabilitarGravar = true;
    } else if(this.duplicar) {
      this.gerarRota();
    }
  }

  verificaEnderecosPreenchidos(rota: RotaModel) {
    const enderecosNaoPreenchidos = rota.pontoParadaRota.filter(ppr => !ppr.dadosEndereco || !ppr.dadosEndereco.cep);
    let resultado: boolean = true;

    if(enderecosNaoPreenchidos && enderecosNaoPreenchidos.length > 0) {
      resultado = false;
    }

    return resultado;
  }

  salvar(): void {

    if(!this.form.valid) {
      this.snackbarService.openSnackBar('Por favor, preencha todos os campos obrigatórios antes de salvar.', 'Close', 'error-snackbar');
      return;
    }

    const rota: RotaModel = new RotaModel();
    Object.assign(rota, this.form.value);
    rota.rotaWaypointList = this.rota.rotaWaypointList;
    rota.coordinates = this.rota.coordinates;

    if(this.verificaEnderecosPreenchidos(rota) == false) {
      this.snackbarService.openSnackBar('Por favor, preencha todos endereços antes de prosseguir.', 'Close', 'error-snackbar');
      return;
    }

    this.loadingService.showLoading(true, 'Salvando rota... Por favor, aguarde!');
    this.rotaService.salvar(rota).subscribe({
      next: (res: any) => {
        this.loadingService.showLoading(false);
        this.snackbarService.openSnackBar('Rota gravada com Sucesso.', 'Close', 'sucess-snackbar')
        this.dialogRef.close(true);
      },
      error: (err: any) => {
        this.loadingService.showLoading(false);
        this.snackbarService.openSnackBar('Erro ao gravar Rota.', 'Close', 'error-snackbar')
      }
    });
  }

  //rota alternativa somente é persistida dentro do objeto monitoramento
  salvarRotaAlternativa(): void {
    const rota: RotaModel = new RotaModel();
    Object.assign(rota, this.form.value);
    this.loadingService.showLoading(true, 'Salvando rota... Por favor, aguarde!');
    rota.coordinates = this.rota.coordinates;
    this.rotaService.salvarRotaAlternativa(rota, this.rota.monitoramentoId).subscribe({
      next: (res: any) => {
        this.loadingService.showLoading(false);
        this.snackbarService.openSnackBar('Rota gravada com Sucesso.', 'Close', 'sucess-snackbar')
        this.dialogRef.close(true);
      },
      error: (err: any) => {
        this.loadingService.showLoading(false);
        this.snackbarService.openSnackBar('Erro ao gravar Rota.', 'Close', 'error-snackbar')
      }
    });
  }

  cancelar(): void {

  }

  getNomeRota(): string {
    return this.form.get('nome')?.value;
  }

  gerarRota(reconstruirRota: boolean = false) {
    const quantidadePontoParadaRota: number = this.rota.pontoParadaRota.length;

    const latLngOrigem: {lat: number, lng: number} = {
      lat: this.rota.pontoParadaRota[0].location.lat,
      lng: this.rota.pontoParadaRota[0].location.lng
    };

    const latLngDestino: {lat: number, lng: number} = {
      lat: this.rota.pontoParadaRota[quantidadePontoParadaRota - 1].location.lat,
      lng: this.rota.pontoParadaRota[quantidadePontoParadaRota - 1].location.lng
    }

    const waypoints: google.maps.DirectionsWaypoint[] = [];

    if(reconstruirRota) {
      if(this.rota.pontoParadaRota.length > 2) {
        this.rota.pontoParadaRota.forEach(pp => {
          waypoints.push({location:  pp.location, stopover: true});
        });
      }
    } else {
      if(this.rota.rotaWaypointList){
        this.rota.rotaWaypointList.forEach(wp => {
          waypoints.push({location: {lat: wp.location.lat, lng: wp.location.lng}, stopover: wp.stopover});
        });
      }

    }

    waypoints.shift();
    waypoints.pop();

    this.gmapsCmp.gerarRota(latLngOrigem, latLngDestino, waypoints);
  }

  rotaGerada(newRoute: google.maps.DirectionsResult | null) {

    this.rota.coordinates = [];
    this.rota.rotaWaypointList = [];
    this.routeResponse = newRoute;

    this.routeResponse?.routes[0].overview_path?.map(p => {
      const latLng: {lat: number, lng: number} = p.toJSON();
      this.rota.coordinates.push(latLng);
    });

    if(this.rota.pontoParadaRota.length >= 2) {
      newRoute.routes.forEach((route: any) => {
        route.legs.forEach((leg: google.maps.DirectionsLeg, i: number) => {
          this.rota.rotaWaypointList.push({location: {lat: leg.steps[0].start_location.lat(), lng: leg.steps[0].start_location.lng()}, stopover: true});

          leg.via_waypoints.forEach((waypoint: any) => {
            this.rota.rotaWaypointList.push({location: waypoint.toJSON(), stopover: false});
          });

          if(i == route.legs.length - 1) {
            this.rota.rotaWaypointList.push(
              {
                location: {lat: leg.steps[leg.steps.length - 1].start_location.lat(), lng: leg.steps[leg.steps.length - 1].start_location.lng()},
                stopover: true
              });
          }
        });
      });
    }
    this.form.get('distancia')?.setValue(this.gmapsCmp.computeTotalDistance(newRoute));
    this.form.get('pontoParadaRota')?.setValue(this.rota.pontoParadaRota);
    this.form.get('origem')?.setValue(this.rota.pontoParadaRota[0].formattedAddress);
    this.form.get('destino')?.setValue(this.rota.pontoParadaRota[this.rota.pontoParadaRota.length-1].formattedAddress);
  }

  mapComponentLoaded(isLoaded: boolean) {
    if(isLoaded === true && this.editing === true) {
      this.setPolyline();
    }
  }

  setPolyline(): void {
    if (this.data && this.data.rotaRealizada) {
      this.polylineList = [{
        vertices: this.data.rotaRealizada.vertices.map((latlng: any) => { return {lat: latlng.lat, lng: latlng.lng}}),
        polylineOptions: {
          strokeColor: '#EE5A24',
          strokeWeight: 6,
          strokeOpacity: 0.8,
          icons: [{
            repeat: '10%',
            // offset: '100%'
          }],
        }
      }];

      this.gmapsCmp.adicionarMarker(
        {
          lat: this.data.rotaRealizada.vertices[0].lat,
          lng: this.data.rotaRealizada.vertices[0].lng,
          markerOptions: {draggable: false, label: "I"}
        }
      );
    } else if(this.data.rota) {

      const latLngArray = this.data.rota.pontos.coordinates.map((xy: any) => {return {'lat': xy.y, 'lng': xy.x}});

      this.polylineList = [{
        vertices: latLngArray,
        polylineOptions: {
          strokeColor: '#EE5A24',
          strokeWeight: 6,
          strokeOpacity: 0.8,
          icons: [{
            repeat: '10%',
            // offset: '100%'
          }],
        }
      }];

      this.gmapsCmp.adicionarMarker(
        {
          lat: latLngArray[0].lat,
          lng: latLngArray[0].lng,
          markerOptions: {draggable: false, label: "A"}
        }
      );

      this.gmapsCmp.adicionarMarker(
        {
          lat: latLngArray[latLngArray.length - 1].lat,
          lng: latLngArray[latLngArray.length - 1].lng,
          markerOptions: {draggable: false, label: "B"}
        }
      );
    }
  }

  addMarker(gmapsAutocompleteResult: GmapsAutocompleteResult) {
    this.gmapsCmp.adicionarMarker(gmapsAutocompleteResult.location);
  }

  removeMarker(gmapsAutocompleteResult: GmapsAutocompleteResult) {
    this.gmapsCmp.removerMarker(gmapsAutocompleteResult.location);
    this.gerarRota(true);
  }

  preencheValorGerenciador(event: MatSelectChange){
    if(event != null){
      const clienteTransportadoraId: string = event.value;
      const transportadora: ClienteTransportadorModel = this.clientesTransportador.find( t => t._id == clienteTransportadoraId);

      this.form.patchValue({
        clienteGerenciadorId: transportadora.clienteGerenciadorRiscoId,
        clienteTransportadorId: clienteTransportadoraId
      });
    }
  }

  buscarTransportadorasMatrizesCadastradas() {
    this.loadingService.showLoading(true, 'Buscando transportadores... Por favor, aguarde!');
    this.clienteTransportadorService.buscarTodosTransportadoresMatriz().subscribe({
      next: (res) => {
        this.clientesTransportador = res;
        this.loadingService.showLoading(false);
      },
      error: (err)  => {
        this.loadingService.showLoading(false);
      }
    });
  }

  localSelecionado(local: {i: number, dadosEnderecoModel: DadosEnderecoModel}) {
    this.rota.pontoParadaRota[local.i].formattedAddress = local.dadosEnderecoModel.enderecoCompleto;
    this.rota.pontoParadaRota[local.i].location = local.dadosEnderecoModel.latLng;
    this.rota.pontoParadaRota[local.i].dadosEndereco = local.dadosEnderecoModel;

    if (local.i === 0) {
      this.gmapsCmp.adicionarMarker({
        lat: local.dadosEnderecoModel.latLng.lat,
        lng: local.dadosEnderecoModel.latLng.lng,
        markerOptions: { label: "A" }
      });
    }
  }

  localRemovido(index: number) {
    if(this.rota.pontoParadaRota.length <= 2) {
      return;
    }
    this.rota.removeElem(index);
  }

  localAdicionado(index: number) {
    this.rota.addElem(index);
  }

  getDadosEnderecoPontoParada(pontoParadaRota: PontoParadaRota) {
    if(!pontoParadaRota.dadosEndereco) {
      pontoParadaRota.dadosEndereco = new DadosEnderecoModel();
    }

    return pontoParadaRota.dadosEndereco;
  }
}
