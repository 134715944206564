import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor(
    private router: Router
  ) { }

  homeClienteTransportador(): void {
    this.router.navigate(['modulo-cliente-transportador']);
  }

  homeClienteGerenciadorRisco(): void {
    this.router.navigate(['modulo-cliente-gerenciador']);
  }
  
  homeEcotech() {
    this.router.navigate(['modulo-ecotech']);
  }
  
  loginGerenciador() {
    this.router.navigate(['login-gerenciador']);
  }
}
