import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemplateMensagemModel } from 'src/app/core/model/template-mensagem-model';
import { TemplateMensagemPesquisaModel } from 'src/app/core/model/template-mensagem-pesquisa-model';
import { ParentService } from 'src/app/core/service/parent.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateMensagemWppService extends ParentService {

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  listar(pesquisa: TemplateMensagemPesquisaModel) {
    const baseUrl = `${environment.cadastro.templateMensagem.pesquisar}`;
    const params = new HttpParams({fromObject: pesquisa as any});
    return super.get(baseUrl, params);
  }

  gravar(template: TemplateMensagemModel){
    const url = `${environment.cadastro.templateMensagem.gravar}`;
    return super.post(url, template);
  }

  alterarStatus(templateId: string) {
    const url = `${environment.cadastro.templateMensagem.alterarStatus}?id=${templateId}`;
    return super.get(url);
  }
}
