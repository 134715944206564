import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TecnologiaRastreioPipe } from '../../pipes/tecnologia-rastreio.pipe';
import { LoadingService } from '../../service/loading.service';
import { MigracaoService } from '../../service/migracao.service';
import { SnackbarService } from '../../service/snackbar.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'app-espelhar-veiculo-dialog',
  templateUrl: './espelhar-veiculo-dialog.component.html',
  styleUrls: ['./espelhar-veiculo-dialog.component.scss'],
  providers: [TecnologiaRastreioPipe]
})
export class EspelharVeiculoDialogComponent {

  contaRastreio: any;

  constructor(
    public dialog: MatDialog,
    private loadingService: LoadingService,
    private migracaoService: MigracaoService,
    private snackBarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public tecnologiaRastreioPipe: TecnologiaRastreioPipe,
    private dialogRefEspelharVeiculo: MatDialogRef<EspelharVeiculoDialogComponent>,

  ) {
  }

  confirmarEspelhamento() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: 'auto',
      data: {
        titulo: 'Atenção',
        subtitulo: `Espelhar os veículos do cliente: ${this.data.clienteTransportador.razaoSocial} -
         ${this.tecnologiaRastreioPipe.transform(this.contaRastreio.tecnologiaRastreio)} - ${this.contaRastreio.usuario} ?`}
    });

    dialogRef.afterClosed().subscribe(resultado => {

      if(resultado === 'true') {
        this.espelharVeiculos();

      }
    });
  }
  espelharVeiculos() {
    this.loadingService.showLoading(true, 'Espelhando veículos... Por favor, aguarde!');
    this.migracaoService.atualizaBaseVeiculos(this.data.clienteTransportador._id, this.contaRastreio._id).subscribe({
      next: (result) => {
        this.snackBarService.openSnackBar('Veículos espelhados com sucesso.', 'Close', 'sucess-snackbar')
        this.loadingService.showLoading(false);
        this.dialogRefEspelharVeiculo.close();
      },
      error: (err) => {
        this.snackBarService.openSnackBar('Erro ao espelhar veículos.', 'Close', 'error-snackbar')
        this.loadingService.showLoading(false);
        this.dialogRefEspelharVeiculo.close();
      }
    });
  }
}
