import { usuarioCriacao } from './../../core/model/usuario-criacao-model';
import { DadosEnderecoModel } from "src/app/core/model/dados-endereco-model";
import { GmapsAutocompleteResult } from "src/app/core/model/gmaps-autocomplete-result";
import { LatLng } from "src/app/core/model/latlng-model";

export class RotaModel {
    _id: string;
    nome: string;
    descricao: string;
    distancia: number;
    pontos: any;
    idUsuario: string;
    origem: string;
    destino: string;
    coordinates: LatLng[] = [];
    ativo: boolean = true;
    pontoParadaRota: PontoParadaRota[] = [];
    rotaWaypointList: {location: {lat: number, lng: number}, stopover: boolean}[] = [];
    clienteTransportadorId: string;
    clienteGerenciadorId: string;
    rotaAlternativa: boolean = false;
    monitoramentoId: string;
    createdAt: Date;
    usuarioCriacao: usuarioCriacao;

    addOrigemDestino() {
        this.pontoParadaRota = [{
            formattedAddress: '',
            location: {lat: 0, lng: 0},
            dadosEndereco: new DadosEnderecoModel()
        },{
            formattedAddress: '',
            location: {lat: 0, lng: 0},
            dadosEndereco: new DadosEnderecoModel()
        }];
    }
    addElem(i: number) {
        this.pontoParadaRota.splice(i+1, 0 ,{
            formattedAddress: '',
            location: {lat: 0, lng: 0},
            dadosEndereco: new DadosEnderecoModel()
        });
    }
    removeElem(i: number) {
        this.pontoParadaRota.splice(i, 1);
    }
}

export class PontoParadaRota extends GmapsAutocompleteResult {
    dadosEndereco?: DadosEnderecoModel = new DadosEnderecoModel();
}

export class PontoParada {
    _id: string;
    nome: string;
    endereco: string;
    lat: string;
    long: string;
    waypoints?: LatLng[];

}

export class LineString {
    coordinates: Position[] = [];
}

export class Position {
    values: number[] = [];
}
