import { Component, EventEmitter, Output } from '@angular/core';
import { ClienteTransportadorModel } from '../../model/cliente-transportador-model';
import { ClienteTransportadorService } from 'src/app/private/cliente-transportador/cliente-transportador.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-importar-arquivo',
  templateUrl: './importar-arquivo.component.html',
  styleUrls: ['./importar-arquivo.component.scss']
})
export class ImportarArquivoComponent {

  @Output() importarEvent: EventEmitter<any> = new EventEmitter<any>();
  transportadorId: string;
  transportadoraList: ClienteTransportadorModel[] = [];
  formData: FormData = new FormData();
  nomeArquivo: string;

  constructor(
    private clienteTransportadorService: ClienteTransportadorService,
    private dialogRef: MatDialogRef<ImportarArquivoComponent>
  ) {
    this.buscarTransportadorasFiliaisCadastradas();
  }

  importar() {
    this.dialogRef.close({transportadorId: this.transportadorId, formData: this.formData});
  }

  buscarTransportadorasFiliaisCadastradas() {
    this.clienteTransportadorService.buscarTodosTransportadores().subscribe({
      next: (res) => {
        this.transportadoraList = res;
      },
      error: (err)  => {
        console.log(err)
      }
    });
  }

  onFileSelected(event: any) {
    this.formData.delete("file");
    this.formData.append("file", event.target.files[0]);
    this.nomeArquivo = event.target.files[0].name;
  }
}
