import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ParentService } from './parent.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsGeocodeService extends ParentService{

  constructor(httpCliente: HttpClient) {
    super(httpCliente);
  }

  geocode(address: string): Observable<any> {
    return super.get(`google-maps-geocoding/geocode?address=${address}`);

//     return of([
//       {
//           "addressComponents": [
//               {
//                   "longName": "31330-636",
//                   "shortName": "31330-636",
//                   "types": [
//                       "POSTAL_CODE"
//                   ]
//               },
//               {
//                   "longName": "Rua José Alexandre Ferreira",
//                   "shortName": "R. José Alexandre Ferreira",
//                   "types": [
//                       "ROUTE"
//                   ]
//               },
//               {
//                   "longName": "Paquetá",
//                   "shortName": "Paquetá",
//                   "types": [
//                       "POLITICAL",
//                       "SUBLOCALITY",
//                       "SUBLOCALITY_LEVEL_1"
//                   ]
//               },
//               {
//                   "longName": "Belo Horizonte",
//                   "shortName": "Belo Horizonte",
//                   "types": [
//                       "ADMINISTRATIVE_AREA_LEVEL_2",
//                       "POLITICAL"
//                   ]
//               },
//               {
//                   "longName": "Minas Gerais",
//                   "shortName": "MG",
//                   "types": [
//                       "ADMINISTRATIVE_AREA_LEVEL_1",
//                       "POLITICAL"
//                   ]
//               },
//               {
//                   "longName": "Brasil",
//                   "shortName": "BR",
//                   "types": [
//                       "COUNTRY",
//                       "POLITICAL"
//                   ]
//               }
//           ],
//           "formattedAddress": "R. José Alexandre Ferreira - Paquetá, Belo Horizonte - MG, 31330-636, Brasil",
//           "postcodeLocalities": null,
//           "geometry": {
//               "bounds": {
//                   "northeast": {
//                       "lat": -19.8845283,
//                       "lng": -43.9889587
//                   },
//                   "southwest": {
//                       "lat": -19.887613,
//                       "lng": -43.99253170000001
//                   }
//               },
//               "location": {
//                   "lat": -19.8857736,
//                   "lng": -43.99058290000001
//               },
//               "locationType": "APPROXIMATE",
//               "viewport": {
//                   "northeast": {
//                       "lat": -19.8845283,
//                       "lng": -43.9889587
//                   },
//                   "southwest": {
//                       "lat": -19.887613,
//                       "lng": -43.99253170000001
//                   }
//               }
//           },
//           "types": [
//               "POSTAL_CODE"
//           ],
//           "partialMatch": false,
//           "placeId": "ChIJxwsvETCRpgARlJYQ3hMAfaU",
//           "plusCode": null
//       }
//   ])
  }
}
