<h1 mat-dialog-title>
    Grupos de notificação
</h1>
<mat-dialog-content style="margin-top: 1em;">
    <div class="grid">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <h1>
                        <button mat-raised-button cdkFocusInitial (click)="adicionar()">Adicionar grupo</button>
                    </h1>

                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h4 style="text-align: center;" *ngIf="this.dataSource && this.dataSource.data && this.dataSource.data.length == 0; else tableTemplate">
                    Nenhum grupo de notificação cadastrado
                </h4>
                <ng-template #tableTemplate>
                    <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="tipoNotificacao">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenando por grupo contato">Notificar motorista</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.tipoNotificacao == tipoNotificacaoMotorista ? 'Sim' : 'Não' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nomeGrupoContato">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenando por grupo contato">Grupo Contato</th>
                            <td mat-cell *matCellDef="let element">{{ element.grupoContato ? element.grupoContato?.nome : 'N/A' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="nomeTemplate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenando por template">Template</th>
                            <td mat-cell *matCellDef="let element">{{ element.templateMensagem.nome }}</td>
                        </ng-container>
                        <ng-container matColumnDef="numeroAlertasParaNotificar">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenando por numeroAlertasParaNotificar">Número de alertas para notificar</th>
                            <td mat-cell *matCellDef="let element">{{ element.numeroAlertasParaNotificar }}</td>
                        </ng-container>
                        <ng-container matColumnDef="isNotificacaoMensagemOficial">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenando por Notificacao oficial">Tipo de notificação de mensagem</th>
                          <td mat-cell *matCellDef="let element">{{ element.isNotificacaoMensagemOficial === 'O' ? 'Oficial' : 'Não oficial' }}</td>
                      </ng-container>
                        <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef>Ações</th>
                            <td mat-cell *matCellDef="let element; let i = index">

                                <button mat-icon-button color="primary" matTooltip="Editar" (click)="this.adicionar(this.dataSource.data[i], i)">
                                    <mat-icon>edit</mat-icon>
                                </button>

                                <button mat-icon-button color="warn" matTooltip="Remover" (click)="this.remover(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </ng-template>
            </mat-card-content>
        </mat-card>

    </div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button (click)="cancelar()">Cancelar</button>
    <button mat-button (click)="okClick()">Ok</button>
 </div>
