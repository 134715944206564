<mat-card>
  <mat-card-header>
    <mat-card-title>Eventos Disponíveis</mat-card-title>
    <mat-card-subtitle style="margin-top: 1em">
      <button mat-stroked-button color="primary" *ngIf="this.eventoComposto == true" (click)="eventoCompostoCriado()">Criar evento composto</button>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-selection-list #disponiveis>
    <mat-list-item role="listitem" *ngFor="let evento of listaEventos;let i = index">
      <ng-container *ngIf="this.eventoComposto == false;else selectTemplate">
        <button mat-raised-button color="primary" (click)="selecionarEvento(evento, 0)" >Selecionar</button>
        {{evento.descricaoEvento}}
      </ng-container>

      <ng-template #selectTemplate>
        <mat-checkbox
          [(ngModel)]="this.checkboxMap[i]"
          (change)="selecionarEvento(evento, i)"
          [disabled]="this.eventosSelecionados.length == 2 && !this.eventosSelecionados.includes(evento)">
          {{evento.descricaoEvento}}
        </mat-checkbox>
      </ng-template>

    </mat-list-item>
  </mat-selection-list>
</mat-card>
