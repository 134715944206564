import { Injectable } from '@angular/core';
import { ParentService } from './parent.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MigracaoService extends ParentService {

  constructor(http: HttpClient) {
    super(http);
   }

   atualizaBaseVeiculos(clienteTransportadorId: string, contaRastreioId: string){
    const url = `${environment.acoes.migracao.atualizaContaVeiculo}?clienteTransportadorId=${clienteTransportadorId}&contaRastreioId=${contaRastreioId}`;
    return super.get(url);
   }

   atualizaBaseVeiculosGerenciador(contaRastreioId: string){
    const url = `${environment.acoes.migracao.atualizaContaVeiculo}?contaRastreioId=${contaRastreioId}`;
    return super.get(url);
   }
}
