import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './app-paginator.component.html',
  styleUrls: ['./app-paginator.component.scss']
})
export class AppPaginatorComponent {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @Input() pageEvent: PageEvent;
  @Output() pageEventFired: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Input() showTotalRegistros: boolean = true;
  constructor() {
  }
}
