import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DadosMotoristaModel } from '../../model/dados-motorista-model';
import { DadosMotoristaService } from './dados-motorista.service';

@Component({
  selector: 'app-dados-motorista',
  templateUrl: './dados-motorista.component.html',
  styleUrls: ['./dados-motorista.component.scss']
})
export class DadosMotoristaComponent {

  loading: boolean = true;
  cpfExiste: boolean = true;
  dadosEncontrados: boolean = true;
  dadosMotoristaModel: DadosMotoristaModel = new DadosMotoristaModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DadosMotoristaModel,
    private dadosMotoristaService: DadosMotoristaService
  ) {
    if(!data.cpf) {
      this.cpfExiste = false;
    } else {
      this.buscarDados();
    }
  }

  private buscarDados(): void {
    this.dadosMotoristaService.buscarDadosMotorista(this.data.cpf).subscribe({
      next:(res: DadosMotoristaModel) => {
        if(!res || !res.nome) {
          this.dadosEncontrados = false;
        } else {
          this.dadosMotoristaModel = res;
        }
      },
      error:(er: any) => {
        console.log(er);
      }
    });
  }
}
