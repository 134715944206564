<h1 mat-dialog-title>{{this.data.composto == true ? 'Seleção de Eventos do PGR' : 'Criar evento composto'}}</h1>


<mat-dialog-content style="margin-top: 1em;">
  
  <mat-grid-list cols="2">
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Evento Composto</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="eventosSelecionados.length > 0; else semEventoSelecionado">
          <mat-selection-list >
            <mat-list-item role="listitem" *ngFor="let evento of eventosSelecionados">
              <button mat-raised-button color="warn" (click)="removerEvento(evento)">Remover</button>
              {{evento.descricaoEvento}}
            </mat-list-item>
          </mat-selection-list>
        </ng-container>
        <ng-template #semEventoSelecionado>
          <mat-selection-list>
            <mat-list-item role="listitem">
              Nenhum evento selecionado
            </mat-list-item>
          </mat-selection-list>
        </ng-template>
      </mat-card>
      
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Eventos Disponíveis</mat-card-title>
        </mat-card-header>
        <mat-selection-list #disponiveis>
          <mat-list-item role="listitem" *ngFor="let evento of listaEventos">
            <button mat-raised-button color="primary" (click)="selecionarEvento(evento)">Adicionar</button>
            <!-- <ng-template #adicionado>
              <button mat-stroked-button color="primary">Adicionado</button>
            </ng-template> -->
             {{evento.descricaoEvento}}
          </mat-list-item>
        </mat-selection-list>      
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  
</mat-dialog-content>



<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="fechar()">Ok</button>
</div>
  