import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmapsAutocompleteComponent } from './gmaps-autocomplete/gmaps-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { LineChartComponent } from './line-chart/line-chart.component';
import { LocalizarVeiculoComponent } from './localizar-veiculo/localizar-veiculo.component';
import { AppGoogleMapsComponent } from './app-google-maps/app-google-maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { LoadingComponent } from './loading/loading.component';
import { EspelharVeiculoDialogComponent } from './espelhar-veiculo-dialog/espelhar-veiculo-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { AppPaginatorComponent } from './app-paginator/app-paginator.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { RotaPrevistaRealizadaComponent } from './rota-prevista-realizada/rota-prevista-realizada.component';
import { SelecaoAcoesComponent } from './selecao-acoes/selecao-acoes.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { SelecaoEventosComponent } from './selecao-eventos/selecao-eventos.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { EventoListComponent } from './evento-list/evento-list.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppPaginatorIntl } from './app-paginator/app-paginator-intl';
import { ImportarArquivoComponent } from './importar-arquivo/importar-arquivo.component';
import { MatIconModule } from '@angular/material/icon';
import { ObservacoesSmComponent } from './observacoes-sm/observacoes-sm.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DadosMotoristaComponent } from './dados-motorista/dados-motorista.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaskModule } from 'ngx-mask';
import { ConfiguracoesEventoAreaRiscoComponent } from './configuracoes-evento-area-risco/configuracoes-evento-area-risco.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HasWritePermissionDirective } from '../directives/has-write-permission.directive';
import { PermissaoService } from '../service/permissao.service';
import { BuscaCepComponent } from './busca-cep/busca-cep.component';
import { DetalhesCepComponent } from './detalhes-cep/detalhes-cep.component';
import { GrupoContatoTemplateComponent } from './grupo-contato-template/grupo-contato-template.component';
import { GrupoContatoTemplateFormComponent } from './grupo-contato-template-form/grupo-contato-template-form.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificacaoMensagemComponent } from './notificacao-mensagem/notificacao-mensagem.component';
import {MatRadioModule} from '@angular/material/radio';
import { ConfiguracoesEventoHorarioProibidoComponent } from './configuracoes-movimentacao-proibida/configuracoes-evento-horario-proibido.component';
import { ConfiguracoesEventosInibidosComponent } from './configuracoes-eventos-inibidos/configuracoes-eventos-inibidos.component';
import { ConfiguracoesEventosInibidosDestinoComponent } from './configuracoes-eventos-inibidos-destino/configuracoes-eventos-inibidos-destino.component';
import { ConfiguracoesEventosInibidosPontoEntregaComponent } from './configuracoes-eventos-inibidos-ponto-entrega/configuracoes-eventos-inibidos-ponto-entrega.component';

@NgModule({
  declarations: [
    GmapsAutocompleteComponent,
    PieChartComponent,
    BarChartComponent,
    ConfirmationComponent,
    LineChartComponent,
    LocalizarVeiculoComponent,
    AppGoogleMapsComponent,
    LoadingComponent,
    EspelharVeiculoDialogComponent,
    AppPaginatorComponent,
    RotaPrevistaRealizadaComponent,
    SelecaoAcoesComponent,
    SelecaoEventosComponent,
    EventoListComponent,
    ImportarArquivoComponent,
    ObservacoesSmComponent,
    DadosMotoristaComponent,
    ConfiguracoesEventoAreaRiscoComponent,
    HasWritePermissionDirective,
    BuscaCepComponent,
    DetalhesCepComponent,
    GrupoContatoTemplateComponent,
    GrupoContatoTemplateFormComponent,
    NotificacaoMensagemComponent,
    ConfiguracoesEventoHorarioProibidoComponent,
    ConfiguracoesEventosInibidosComponent,
    ConfiguracoesEventosInibidosDestinoComponent,
    ConfiguracoesEventosInibidosPontoEntregaComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatOptionModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxLoadingModule,
    MatSelectModule,
    MatPaginatorModule,
    MatCardModule,
    MatListModule,
    MatGridListModule,
    MatCheckboxModule,
    MatIconModule,
    MatDatepickerModule,
    MatExpansionModule,
    NgxMaskModule,
    MatFormFieldModule,
    MatTableModule,
    MatTooltipModule,
    MatRadioModule,
  ],
  exports: [
    GmapsAutocompleteComponent,
    PieChartComponent,
    BarChartComponent,
    ConfirmationComponent,
    LineChartComponent,
    LocalizarVeiculoComponent,
    AppGoogleMapsComponent,
    LoadingComponent,
    EspelharVeiculoDialogComponent,
    AppPaginatorComponent,
    RotaPrevistaRealizadaComponent,
    SelecaoAcoesComponent,
    SelecaoEventosComponent,
    EventoListComponent,
    ImportarArquivoComponent,
    ObservacoesSmComponent,
    DadosMotoristaComponent,
    ConfiguracoesEventoAreaRiscoComponent,
    BuscaCepComponent,
    GrupoContatoTemplateComponent,
    GrupoContatoTemplateFormComponent,
    NotificacaoMensagemComponent,
    HasWritePermissionDirective,
    BuscaCepComponent
  ],
  providers: [{provide: MatPaginatorIntl, useClass: AppPaginatorIntl},
    PermissaoService
  ],

})
export class CoreComponentsModule { }
