import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Chart, registerables} from 'chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, AfterViewInit {

  @Input() titulo: string;
  @Input() subtitulo: string;
  @Input() labelGrafico: string;
  @Input() idGrafico: string;
  @Input() labels: string[];
  @Input() data: number[];
  
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const ctx: any = document.getElementById(this.idGrafico);
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          label: this.labelGrafico,
          data: this.data,
          borderWidth: 1
        }]
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
        scales: {
          y: {
            // display:false
          },
          x: {
            // display:false
          }
        }
      }
    });
  }  
}
