import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import { UsuarioClienteTransportadorModel } from '../model/usuario-cliente-transportador-model';

@Injectable({
  providedIn: 'root'
})
export class PermissaoService {

  constructor(private appStorageService: AppStorageService) {}

  hasWritePermission(urlFuncionalidade: string): boolean {
    let usuario = Object.assign(new UsuarioClienteTransportadorModel, this.appStorageService.getUsuarioData());
    const permissoes = usuario.permissoes;

    if(permissoes)
      return permissoes.some(permissao =>
        permissao.urlFuncionalidade === urlFuncionalidade &&
        permissao.itemPermissao === 'Escrita'
      );
    else
      return true;
  }

}
