import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ParentService } from '../../service/parent.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DadosMotoristaService extends ParentService {

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }
  
  buscarDadosMotorista(cpf: string): Observable<any> {
    return super.get(`${environment.gerenciadorRisco.integracao.getDadosMotorista}?cpf=${cpf}`);
  }
}
