import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentService } from 'src/app/core/service/parent.service';
import { AreaRiscoModel } from './area-risco-model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SpringPageModel } from 'src/app/core/model/spring-page-model';
import { AreaRiscoPesquisa } from 'src/app/core/model/area-risco-pesquisa';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class AreaRiscoService extends ParentService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  salvar(areaRisco: AreaRiscoModel[]): Observable<SpringPageModel>{
    const url = `${environment.gerenciadorRisco.areaRisco.save}`;
    return super.post(url, areaRisco);
  }

  listar(pageEvent: PageEvent,pesquisa:AreaRiscoPesquisa = new AreaRiscoPesquisa()) {
    const url = `${environment.gerenciadorRisco.areaRisco.list}`;
    const params = new HttpParams({fromObject: pesquisa as any})
    .set('page', pageEvent.pageIndex)
    .set('size', pageEvent.pageSize);

    return super.get(url, params);
  }

  listarByCodigo(codigo: string) {
    const url = `${environment.gerenciadorRisco.areaRisco.listByCodigo}?codigo=${codigo}`;
    return super.get(url);
  }

  verificar(lat: number, lng: number) {
    const url = `${environment.gerenciadorRisco.areaRisco.verificar}?lat=${lat}&lng=${lng}`;
    return super.get(url);
  }

  deletar(codigoAreaRisco: string) {
    const url = `${environment.gerenciadorRisco.areaRisco.delete}?codigoAreaRisco=${codigoAreaRisco}`;
    return super.delete(url);
  }

  deletarById(id: string) {
    const url = `${environment.gerenciadorRisco.areaRisco.deleteById}?id=${id}`;
    return super.delete(url);
  }

  verificarUsoPgr(codigo: string) {
    const url = `${environment.gerenciadorRisco.areaRisco.verificarUsoEmPgr}?codigoAreaRisco=${codigo}`;
    return super.get(url);
  }

  importar(transportadorId: String, formData: FormData) {
    const url = `${environment.gerenciadorRisco.areaRisco.importar}?transportadorId=${transportadorId}`;
    return super.post(url, formData);
  }

  alterarStatusArea(idArea: string){
    const url = `${environment.gerenciadorRisco.areaRisco.alterarStatusAreaRisco}?idArea=${idArea}`;
    return super.get(url);
  }
}
