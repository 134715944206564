<h1 mat-dialog-title>Configurações do Evento -  {{this.data.titulo}}</h1>

<mat-dialog-content style="margin-top: 1em;">
  <mat-card>
    <mat-card-content>
      <div class="grid">
        <div class="col-12">
          <mat-form-field class="form_monitoramento" appearance="outline">
            <mat-label>Selecione os eventos para inibição:</mat-label>
            <mat-select [(ngModel)]="eventosInibidosIdsOrigem" multiple>
              <mat-option *ngFor="let event of eventoList" [value]="event._id">
                {{event.descricaoEvento}}
              </mat-option>
            </mat-select>
            <mat-error>Selecione pelo menos uma opção!</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 sm:col-12 md:col-12">
          <mat-form-field appearance="outline">
              <mat-label>Digite o Raio (em metros):</mat-label>
              <input matInput type="number" [(ngModel)]="raioMetros">
          </mat-form-field>
      </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button (click)="cancelBtnClick()">Cancelar</button>
  <button mat-button cdkFocusInitial (click)="okBtnClick()">Ok</button>
</div>
