import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VeiculoModel } from '../../model/veiculo-model';
import { ParentService } from '../../service/parent.service';
import { ILocalizarVeiculo } from './localizar-veiculo-interface';

@Injectable({
  providedIn: 'root'
})
export class LocalizarVeiculoService extends ParentService implements ILocalizarVeiculo {

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }
  
  localizarVeiculo(placa: string, codigo: string): Observable<any> {
    return super.get(`${environment.gerenciadorRisco.veiculo.localizar}?placa=${placa}&codigo=${codigo}`);
  }
}
