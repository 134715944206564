import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-configuracoes-evento-horario-proibido',
  templateUrl: './configuracoes-evento-horario-proibido.component.html',
  styleUrls: ['./configuracoes-evento-horario-proibido.component.scss']
})
export class ConfiguracoesEventoHorarioProibidoComponent {

  inicioProibicao: string;
  fimProibicao: string;
  raioPermitido: string;

  constructor(
    private dialogRef: MatDialogRef<ConfiguracoesEventoHorarioProibidoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.inicioProibicao = this.data.inicioProibicao;
    this.fimProibicao = this.data.fimProibicao;
    this.raioPermitido = this.data.raioPermitido
  }

  okBtnClick() {
    this.dialogRef.close({inicioProibicao: this.inicioProibicao, fimProibicao: this.fimProibicao, raioPermitido: this.raioPermitido});
  }
  cancelBtnClick() {
    this.dialogRef.close(null);
  }
}
