import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, map, Observable, of, startWith, switchMap } from 'rxjs';
import { GoogleMapsGeocodeService } from '../../service/google-maps-geocode.service';
import { GmapsAutocompleteResult } from '../../model/gmaps-autocomplete-result';

@Component({
  selector: 'app-gmaps-autocomplete',
  templateUrl: './gmaps-autocomplete.component.html',
  styleUrls: ['./gmaps-autocomplete.component.scss']
})
export class GmapsAutocompleteComponent implements OnInit {

  @Output()
  pontoSelecionado: EventEmitter<GmapsAutocompleteResult> = new EventEmitter();

  @Input()
  placeholder: string;
  
  @Input()
  addressControl = new FormControl();

  @Input() waypointId: string;

  filteredOptionsDestino: Observable< any []>;

  constructor(private googlemapsGeocodeService: GoogleMapsGeocodeService) {}

  ngOnInit(): void {
    this.filteredOptionsDestino = this.addressControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.filter(val || '')
      })       
    );
  }

  filter(address: any): Observable<GmapsAutocompleteResult[]> {

    if(!address || address.placeId || address.length < 3) return of([]);

    return this.googlemapsGeocodeService.geocode(address).pipe(
      map(response => 
        response.filter(
          (option: any) => {
            return {
              formattedAddress: option.formattedAddress,
              location: option.geometry.location,
            };
          }
        )
      )
    );
  }

  displayFn(googleResponseItem: any): string {
    if (!googleResponseItem) return '';
    return googleResponseItem.address || googleResponseItem.formattedAddress;
  }

  optionSelected(option: any) {
    this.pontoSelecionado.emit({
      formattedAddress: option.option.value.formattedAddress,
      location: option.option.value.geometry.location
    });
  }
}