import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioModel } from '../model/usuario-model';
import { AppStorageService } from './app-storage.service';
import { NavigatorService } from './navigator.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(private appStorageService: AppStorageService, private navigatorService: NavigatorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> > {
    const usuario: UsuarioModel = this.appStorageService.getUsuarioData();
    const Authorization: string = usuario && usuario.token ? usuario.token : '';

    if(this.httpCallNeedsToken(req)) {
      req = req.clone({
        setHeaders: {
          'Authorization': Authorization
        }
      });
    }

    // return next.handle(req);
    return next.handle(req).pipe(catchError(err => {
      if ([401, 403].includes(err.status)) {
        // return await this.getRefreshToken(request, next);
        this.navigatorService.loginGerenciador();
      }

      console.log(err);
      const error = (err && err.error && err.error.message) || err.statusText || err.descricaoErro;
      return throwError(() => err);
    }));
  }

  private httpCallNeedsToken(req: HttpRequest<any>): boolean {
    const urls: string[] = [
      environment.ecotech.auth.login,
      environment.gerenciadorRisco.auth.login,
      environment.transportador.auth.login,
    ];
    const resultado: string | undefined = urls.find((url: string) => req.url.includes(url)) ;
    return resultado && resultado.trim().length ? false : true;
  }
}
