import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AcaoParentModel } from '../../model/evento-pgr-model';
import { SnackbarService } from '../../service/snackbar.service';
import { AcoesEnum } from '../../enum/acoes-enum';
import { GrupoContatoTemplateComponent } from '../grupo-contato-template/grupo-contato-template.component';
import { NotificacaoMensagemModel } from '../../model/notificacao-mensagem-model';

@Component({
  selector: 'app-notificacao-mensagem',
  templateUrl: './notificacao-mensagem.component.html',
  styleUrls: ['./notificacao-mensagem.component.scss']
})
export class NotificacaoMensagemComponent {

  ACAO_ENVIO_WHATSAPP_ENUM = AcoesEnum.ENVIO_WHATSAPP

  itensSelecionados: AcaoParentModel[] = [];

  constructor(
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<NotificacaoMensagemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      itensDisponiveis: AcaoParentModel[],
      itensSelecionados: AcaoParentModel[],
      titulo: string
    },
    private dialog: MatDialog
  ) {
    this.itensSelecionados = Object.assign([], this.data.itensSelecionados);
  }
  
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  selecionar(acaoSelecionada: AcaoParentModel) {

    const novaAcao = new AcaoParentModel();
    Object.assign(novaAcao, acaoSelecionada);
    
    const index: number = this.itensSelecionados.findIndex(item => item.nome == acaoSelecionada.nome);
    if(index != -1) {
      this.snackbarService.openSnackBar('Notificação já selecionada','','');
      return;
    }
    novaAcao.notificacoes = [];
    this.itensSelecionados.push(novaAcao);
  }
  
  remover(itemSelecionado: AcaoParentModel) {
    const index: number = this.itensSelecionados.findIndex(item => item.nome == itemSelecionado.nome);
    this.itensSelecionados.splice(index, 1);
  }

  showBtn(nome: string) {
    return this.itensSelecionados.findIndex(item => item.nome == nome) == -1;
  }

  ok() {
    this.dialogRef.close(this.itensSelecionados);
  }

  cancelar() {
    this.dialogRef.close();
  }

  configurarGrupos(acao: AcaoParentModel) {
    const dialogRef = this.dialog.open(GrupoContatoTemplateComponent, {
      minWidth: '50%',
      minHeight: '50%',
      data: { notificacoesList: acao.notificacoes }
    });

    dialogRef.afterClosed().subscribe((notificacoes: NotificacaoMensagemModel[]) => {
      if(notificacoes) {
        acao.notificacoes = notificacoes;
      }
    });
  }
}
