import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ObservacoesSmService } from './observacoes-sm.service';
import { RastreiosAtivosModel } from '../../model/rastreios-ativos-model';
import { ObservacaoModel } from './observacao-model';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { PageEvent } from '@angular/material/paginator';
import { SpringPageModel } from '../../model/spring-page-model';
import { LoadingService } from '../../service/loading.service';
import { SnackbarService } from '../../service/snackbar.service';
import { MensagemMonitoramentoModel } from '../../model/mensagem-monitoramento-model';

@Component({
  selector: 'app-observacoes-sm',
  templateUrl: './observacoes-sm.component.html',
  styleUrls: ['./observacoes-sm.component.scss']
})
export class ObservacoesSmComponent {

  inicio: Date;
  fim: Date;
  filtro: string;
  novaObservacao: string = "";
  observacoes: ObservacaoModel[] = [];
  pageEvent: PageEvent = {pageIndex: 0, pageSize: 10, length: 0};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {monitoramento: RastreiosAtivosModel | MensagemMonitoramentoModel},
    private observacoesService: ObservacoesSmService,
    public dialog: MatDialog,
    private loadingService: LoadingService,
    private snackBarService: SnackbarService
  ) {
    this.listarObservacoesSm(this.pageEvent);
  }

  listarObservacoesSm(event: PageEvent) {
    this.loadingService.showLoading(true);

    const filtros: any = {};
    if(this.filtro && this.filtro.length > 0) {
      filtros[`descricao`] = this.filtro;
    }
    if(this.inicio) {
      filtros[`inicio`] = this.inicio.toISOString();
    }
    if(this.fim) {
      filtros[`fim`] = this.fim.toISOString();
    }
    if('monitoramentoId' in this.data.monitoramento){
      filtros[`monitoramentoId`] = this.data.monitoramento.monitoramentoId;
    }
    else if('monitoramentoId' in this.data){
      filtros['monitoramentoId'] = this.data.monitoramentoId;
    }


    this.observacoesService.listar(event.pageIndex, event.pageSize, filtros).subscribe(
      {
        next: (result: SpringPageModel) => {
          this.observacoes = result.content || [];
          this.loadingService.showLoading(false);
          this.pageEvent.length = result.totalElements;
          this.pageEvent.pageIndex = result.pageable.pageNumber;
          this.pageEvent.pageSize = result.size;
        },
        error: (err: any) => {
          this.snackBarService.openSnackBar('Erro ao listar observação.', 'Close', 'error-snackbar')
          this.loadingService.showLoading(false);
        }
      }
    );
  }

  enviarNovaObservacao(): void {
    if(!this.novaObservacao) {
      this.snackBarService.openSnackBar('Não é permitido o envio de observações vazias!', 'Close', 'error-snackbar');
      return;
    }

    if(this.novaObservacao.trim().length < 5) {
      this.snackBarService.openSnackBar('Não é permitido o envio de observações com menos de 5 caracteres!', 'Close', 'error-snackbar');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: 'auto',
      data: {
        titulo: 'Atenção',
        subtitulo: `Deseja enviar a nova observacao?`}
    });

    dialogRef.afterClosed().subscribe(resultado => {

      if(resultado === 'true') {
        const observacao: ObservacaoModel = new ObservacaoModel();
        observacao.descricao = this.novaObservacao;
        observacao.monitoramentoId = this.data.monitoramento.monitoramentoId;
        this.observacoesService.gravar(observacao).subscribe(      {
          next: () => {
            this.snackBarService.openSnackBar('Observação gravada com sucesso!', 'Close', 'error-snackbar');
            this.loadingService.showLoading(false);
            this.novaObservacao = "";
            this.listarObservacoesSm(this.pageEvent);
          },
          error: (err: any) => {
            this.snackBarService.openSnackBar('Ocorreram erros ao salvar a nova observação.', 'Close', 'error-snackbar');
            this.loadingService.showLoading(false);
          }
        })
      }
    });
  }
}
