import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EVENTOS } from 'src/app/private/pgr/pgr-form/configuracoes';
import { EventoPGRModel } from '../../model/evento-pgr-model';
import { SnackbarService } from '../../service/snackbar.service';

@Component({
  selector: 'app-selecao-eventos',
  templateUrl: './selecao-eventos.component.html',
  styleUrls: ['./selecao-eventos.component.scss']
})
export class SelecaoEventosComponent {

  @Input() eventosSelecionados: EventoPGRModel[] = [];

  listaEventos: EventoPGRModel[] = EVENTOS;

  constructor(
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: {eventos: EventoPGRModel[], composto: boolean},
    private dialogRef: MatDialogRef<SelecaoEventosComponent>,
  ) {
    this.eventosSelecionados = data.eventos;

    this.data.eventos.forEach((e: EventoPGRModel, i) => {
      e.idDinamico = i;
    });
  }

  selecionarEvento(eventoSelecionado: any) {

    if(this.data.composto == true && this.eventosSelecionados.length == 2) {
      this.snackbarService.openSnackBar('Eventos compostos podem conter apenas 2 eventos simples.', 'Close', 'sucess-snackbar');
      return;
    }

    // Eventos podem ser repetidos
    // const index: number = this.eventosSelecionados.findIndex(evento => evento.nomeEvento == eventoSelecionado.nomeEvento);
    // if(index != -1) {
    //   this.snackbarService.openSnackBar('Evento já selecionado','','');
    //   return;
    // }
    // seta id dinamico para o evento selecionado
    eventoSelecionado.idDinamico = this.eventosSelecionados.length;
    this.eventosSelecionados.push(eventoSelecionado);
  }
  removerEvento(eventoSelecionado: any) {
    const index: number = this.eventosSelecionados.findIndex(evento => evento.idDinamico == eventoSelecionado.idDinamico);
    this.eventosSelecionados.splice(index, 1);
  }

  // showBtn(nomeEvento: string) {
  //   return this.eventosSelecionados.findIndex(evento => evento.nomeEvento == nomeEvento) == -1;
  // }

  fechar() {
    this.dialogRef.close(this.eventosSelecionados);
  }
}
