import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DadosEnderecoModel } from '../../model/dados-endereco-model';
import { MatDialog } from '@angular/material/dialog';
import { DetalhesCepComponent } from '../detalhes-cep/detalhes-cep.component';

@Component({
  selector: 'app-busca-cep',
  templateUrl: './busca-cep.component.html',
  styleUrls: ['./busca-cep.component.scss']
})
export class BuscaCepComponent {

  @Output() localSelecionado: EventEmitter<{i: number, dadosEnderecoModel: DadosEnderecoModel}> = new EventEmitter();
  @Output() localRemovido: EventEmitter<number> = new EventEmitter();
  @Output() localAdicionado: EventEmitter<number> = new EventEmitter();

  constructor(public dialog: MatDialog) {
    console.log(this.dadosEnderecoModel);
  }

  @Input() index: number;
  @Input() dadosEnderecoModel: DadosEnderecoModel = new DadosEnderecoModel();

  @Input() disableControls: boolean = false;
  
  buscarCEP() {
    const dialogRef = this.dialog.open(DetalhesCepComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe((resultado: {dadosEnderecoModel: DadosEnderecoModel}) => {
      if(resultado) {
        this.dadosEnderecoModel = resultado.dadosEnderecoModel;
        this.localSelecionado.emit({i: this.index, dadosEnderecoModel: this.dadosEnderecoModel});
      }
    });
  }

  remover() {
    this.localRemovido.emit(this.index);
  }

  adicionar() {
    this.localAdicionado.emit(this.index);
  }
}