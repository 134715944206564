export class DadosMotoristaModel {
  _id: string;
  nome: string;
  cpf: string;
  numeroCNH: string;
  telefone: string;
  clienteTransportadorId: string;
  clienteGerenciadorId: string;

  // Dados extras provenientes da integração com outras tecnologias
  endereco: string;
  numeroEndereco: string;
  complemento: string;
  cidade: string;
  estado: string;
  bairro: string;
  cep: string;
  email: string;
  dataNascimento: string;
  contatosList: [];
  placa: string;
}
