import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MonitoramentoService } from 'src/app/private/solicitar-monitoramento/monitoramento.service';
import { AppGoogleMapsComponent } from '../app-google-maps/app-google-maps.component';
import { LoadingService } from '../../service/loading.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RotaModel } from 'src/app/private/rota/rota-model';
import { RotaFormComponent } from 'src/app/private/rota/rota-form/rota-form.component';
import { RotaService } from 'src/app/private/rota/rota.service';
import { SnackbarService } from '../../service/snackbar.service';

@Component({
  selector: 'app-rota-prevista-realizada',
  templateUrl: './rota-prevista-realizada.component.html',
  styleUrls: ['./rota-prevista-realizada.component.scss']
})
export class RotaPrevistaRealizadaComponent implements OnInit {

  @ViewChild(AppGoogleMapsComponent) gmapsCmp: AppGoogleMapsComponent;

  monitoramentoId: string;
  placa: string;
  rotaId: string;
  existeRotaAlternativa: boolean = false;
  verticesList: {vertices: google.maps.LatLngLiteral[], polylineOptions: google.maps.PolylineOptions}[]=[];

  constructor(
    private monitoramentoService: MonitoramentoService,
    private loadingService: LoadingService,
    private rotaService: RotaService,
    private snackbarService: SnackbarService,
    @Inject (MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.monitoramentoId = this.data.monitoramentoId;
    this.placa = this.data.placa;
  }

  res: any;

  ngOnInit(): void {
    this.loadingService.showLoading(true, 'Aguarde, carregando rotas');
    this.buscaHistoricoRota();
  }


  buscaHistoricoRota(): void {
    this.monitoramentoService.buscarHistoricoRota(this.monitoramentoId).subscribe({
      next:(res: any) => {
        this.createVerticesList(res);
        this.res = res;
        this.rotaId = res.rotaId;
        this.existeRotaAlternativa = res.rotaAlternativa && res.rotaAlternativa.length > 0;
        this.loadingService.showLoading(false);
      },
      error:(err: any) => {
        console.log(err);
        this.loadingService.showLoading(false);
      },
      complete:() => {
        this.addMarkers(this.res);
      }
    });
  }

  createVerticesList(rotaPrevistaRealizadaModel: any) {
    this.verticesList = [];
    let rotaPrevistaVertices: any = [];
    let rotaAlternativaVertices: any = [];
    if(rotaPrevistaRealizadaModel.rotaPrevista) {
      rotaPrevistaVertices = rotaPrevistaRealizadaModel.rotaPrevista.map((latlng: any) => { return {lat: latlng.lng, lng: latlng.lat}})
    }

    if(rotaPrevistaRealizadaModel.rotaAlternativa){
      rotaAlternativaVertices = rotaPrevistaRealizadaModel.rotaAlternativa.map((latlng: any) => { return {lat: latlng.lng, lng: latlng.lat}})
    }

    this.verticesList[0] = {
      vertices: rotaPrevistaVertices,
      polylineOptions: {
        strokeColor: '#1B1464',
        strokeWeight: 6,
        strokeOpacity: 0.8,
        icons: [{
          //TODO: MELHORAR PQ TA MTO FEIO
          // icon: {
          //   path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          //   fillColor: 'black',
          //   strokeColor: 'black',
          // },
          repeat: '10%'
          // offset: '100%'
        }],
        editable: false
      }
    }

    this.verticesList[1] = {
      vertices: rotaPrevistaRealizadaModel.rotaRealizada,
      polylineOptions: {
        strokeColor: '#EE5A24',
        strokeWeight: 6,
        strokeOpacity: 0.8,
        icons: [{
          //TODO: MELHORAR PQ TA MTO FEIO
          // icon: {
          //   path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          //   fillColor: 'black',
          //   strokeColor: 'black',
          // },
          repeat: '10%'
        }],
        editable: false
      }
    }

    this.verticesList[2] = {
      vertices: rotaAlternativaVertices,
      polylineOptions: {
        strokeColor: '#B0B0B0',
        strokeWeight: 6,
        strokeOpacity: 0.8,
        icons: [{
          //TODO: MELHORAR PQ TA MTO FEIO
          // icon: {
          //   path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          //   fillColor: 'black',
          //   strokeColor: 'black',
          // },
          repeat: '10%'
        }],
        editable: false
      }
    }
  }
  mapComponentLoaded(loaded: boolean) {
    if(loaded === true) {
    }
  }

  addMarkers(rotaPrevistaRealizadaModel: any) {

    if(rotaPrevistaRealizadaModel.rotaPrevista) {
      this.gmapsCmp.adicionarMarker(
        {
          lat: rotaPrevistaRealizadaModel.rotaPrevista[0].lng,
          lng: rotaPrevistaRealizadaModel.rotaPrevista[0].lat,
          markerOptions: {draggable: false, label: "A"}
        }
      );

      this.gmapsCmp.adicionarMarker(
        {
          lat: rotaPrevistaRealizadaModel.rotaPrevista[rotaPrevistaRealizadaModel.rotaPrevista.length-1].lng,
          lng: rotaPrevistaRealizadaModel.rotaPrevista[rotaPrevistaRealizadaModel.rotaPrevista.length-1].lat,
          markerOptions: {draggable: false, label: "B"}
        }
      );
    }

    if(rotaPrevistaRealizadaModel.rotaAlternativa) {
      this.gmapsCmp.adicionarMarker(
        {
          lat: rotaPrevistaRealizadaModel.rotaAlternativa[0].lng,
          lng: rotaPrevistaRealizadaModel.rotaAlternativa[0].lat,
          markerOptions: {draggable: false, label: "C"}
        }
      );

      this.gmapsCmp.adicionarMarker(
        {
          lat: rotaPrevistaRealizadaModel.rotaAlternativa[rotaPrevistaRealizadaModel.rotaAlternativa.length-1].lng,
          lng: rotaPrevistaRealizadaModel.rotaAlternativa[rotaPrevistaRealizadaModel.rotaAlternativa.length-1].lat,
          markerOptions: {draggable: false, label: "D"}
        }
      );
    }

    if(rotaPrevistaRealizadaModel.rotaRealizada && rotaPrevistaRealizadaModel.rotaRealizada.length) {
      this.gmapsCmp.adicionarMarker(
        {
          lat: rotaPrevistaRealizadaModel.rotaRealizada[0].lat,
          lng: rotaPrevistaRealizadaModel.rotaRealizada[0].lng,
          markerOptions: {draggable: false, label: "I"}
        }
      );
      this.gmapsCmp.adicionarMarker(
        {
          lat: rotaPrevistaRealizadaModel.rotaRealizada[rotaPrevistaRealizadaModel.rotaRealizada.length-1].lat,
          lng: rotaPrevistaRealizadaModel.rotaRealizada[rotaPrevistaRealizadaModel.rotaRealizada.length-1].lng,
          markerOptions: {draggable: false, icon: 'assets/truck-32.png'}
        }
      );
    }
  }

  adicionarRotaAlternativa(): void {

    this.rotaService.buscarRotaPorId(this.rotaId).subscribe({
      next: (res: any) => {
        let rota: RotaModel = new RotaModel();
        rota = res;
        rota.rotaAlternativa = true;
        rota.monitoramentoId = this.monitoramentoId;
        const dialogRef = this.dialog.open(RotaFormComponent, {
          minWidth: '100%',
          height: '100%',
          data: {rota, rotaRealizada: this.verticesList[1]}
        });

        dialogRef.afterClosed().subscribe(resultado => {
          this.buscaHistoricoRota();
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  removerRotaAlternativa(): void {
    this.loadingService.showLoading(true, "Excluindo rota alternativa, aguarde...");
    this.rotaService.removerRotaAlternativa(this.monitoramentoId).subscribe({
      next: (res: any) => {
        this.snackbarService.openSnackBar("Rota alternativa excluída com sucesso!");
        this.buscaHistoricoRota();
        this.loadingService.showLoading(false);
      },
      error: (err: any) => {
        this.loadingService.showLoading(false);
        this.snackbarService.openSnackBar("Erro ao excluir rota alternativa");
      }
    });
  }


}
