<div class="flex box">
    <div class="cep-container">
        <div class="flex align-items-center">
            <mat-form-field appearance="outline" class="mat-form-field-no-margin-bottom">
                <input 
                    matInput 
                    type="text" 
                    mask="00000-000" 
                    placeholder="Buscar por CEP" 
                    [(ngModel)]="this.dadosEnderecoModel.cep"
                    disabled="true">
                <button matSuffix mat-mini-fab color="primary" style="margin-right: 1em;" (click)="buscarCEP()"
                    *ngIf="disableControls == false">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="endereco" *ngIf="this.dadosEnderecoModel && this.dadosEnderecoModel.enderecoCompleto">
            {{this.dadosEnderecoModel?.enderecoCompleto}}
        </div>
    </div>
    <div class="icon-container" *ngIf="disableControls == false">
        <mat-icon color="warn" class="acao" (click)="remover()">delete</mat-icon>
        <mat-icon color="primary" class="acao" (click)="adicionar()">add</mat-icon>
    </div>
</div>
