<h1 mat-dialog-title>Dados do Motorista - Veículo: {{this.data.placa}}</h1>
<div mat-dialog-content>
    <ng-container *ngIf="cpfExiste == true; else usuarioSemCpf"></ng-container>
    <ng-template #usuarioSemCpf>
        <p>Usuário não possui CPF registrado na base de dados da Ecotech</p>
    </ng-template>

    <mat-accordion multi>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Dados Pessoais
                </mat-panel-title>
                <mat-panel-description style="justify-content: end;">
                    <mat-icon>account_circle</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="grid">
                <div class="col-12 sm:col-12 md:col-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome:</mat-label>
                        <input matInput type="text" [(ngModel)]="dadosMotoristaModel.nome" [readonly]="true">
                    </mat-form-field>
                </div>
                <div class="col-12 sm:col-12 md:col-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone:</mat-label>
                        <input matInput type="text" [(ngModel)]="dadosMotoristaModel.telefone" [readonly]="true" mask="(00) 00000-0000">
                    </mat-form-field>
                </div>
                <div class="col-12 sm:col-12 md:col-4">
                    <mat-form-field appearance="outline">
                        <mat-label>CPF:</mat-label>
                        <input matInput type="text" [(ngModel)]="dadosMotoristaModel.cpf" [readonly]="true" mask="000.000.000-00 || 00.000.000/0000-00">
                    </mat-form-field>
                </div>
                <div class="col-12 sm:col-12 md:col-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Email:</mat-label>
                        <input matInput type="text" [(ngModel)]="dadosMotoristaModel.email" [readonly]="true">
                    </mat-form-field>
                </div>
                <div class="col-12 sm:col-12 md:col-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Data de Nascimento:</mat-label>
                        <input matInput type="text" [(ngModel)]="dadosMotoristaModel.dataNascimento" [readonly]="true" date="dd/MM/yyyy">
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Endereço
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon>map</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="grid">
            <div class="col-12 sm:col-12 md:col-4">
                <mat-form-field appearance="outline">
                    <mat-label>Rua:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.endereco" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="col-12 sm:col-12 md:col-2">
                <mat-form-field appearance="outline">
                    <mat-label>Número:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.numeroEndereco" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="col-12 sm:col-12 md:col-3">
                <mat-form-field appearance="outline">
                    <mat-label>Bairro:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.bairro" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="col-12 sm:col-12 md:col-3">
                <mat-form-field appearance="outline">
                    <mat-label>CEP:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.cep" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="col-12 sm:col-12 md:col-4">
                <mat-form-field appearance="outline">
                    <mat-label>Complemento:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.complemento" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="col-12 sm:col-12 md:col-4">
                <mat-form-field appearance="outline">
                    <mat-label>Cidade:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.cidade" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="col-12 sm:col-12 md:col-4">
                <mat-form-field appearance="outline">
                    <mat-label>Estado:</mat-label>
                    <input matInput type="text" [(ngModel)]="dadosMotoristaModel.estado" [readonly]="true">
                </mat-form-field>
            </div>
        </div>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Day of the trip
            </mat-panel-title>
            <mat-panel-description>
              Inform the date you wish to travel
              <mat-icon>date_range</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field>
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
          </mat-form-field>
          <mat-datepicker #picker></mat-datepicker>
        </mat-expansion-panel> -->
    </mat-accordion>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Fechar</button>
</div>
