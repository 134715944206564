<mat-form-field appearance="outline">
    <input 
        type="text"
        [placeholder]="placeholder"
        matInput
        [formControl]="addressControl"
        [matAutocomplete]="addressAutocomplete"
        >
    <mat-autocomplete autoActiveFirstOption #addressAutocomplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let option of filteredOptionsDestino | async" [value]="option" >
            {{option.formattedAddress}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>