import { ClienteTransportadorModel } from "./cliente-transportador-model";
import { DadosAlteracao } from "./dados-alteracao-model";
import { UsuarioModel } from "./usuario-model";

export class UsuarioClienteTransportadorModel extends UsuarioModel{
  nome: string;
  cpf: string;
  email: string;
  senha: string;
  telefone: string;
  codigoRecuperacaoSenha: string;
  clienteTransportador: ClienteTransportadorModel;
  clienteTransportadorId: ClienteTransportadorModel;
  clienteGerenciadorRiscoId: string;
  permissoes: any[];
  dadosAlteracao:DadosAlteracao;


  constructor() {
    super();
  }
}
