import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventoPGRModel } from '../../model/evento-pgr-model';
import { SnackbarService } from '../../service/snackbar.service';
import { LoadingService } from '../../service/loading.service';
import { PGRService } from 'src/app/private/pgr/pgr.service';

@Component({
  selector: 'app-evento-list',
  templateUrl: './evento-list.component.html',
  styleUrls: ['./evento-list.component.scss']
})
export class EventoListComponent {
  checkboxMap: any = {};

  // true se for evento compost, false se for evento simples
  @Input() eventoComposto: boolean = false;

  // se for evento simples, esse output será acionado
  @Output() eventoSelecionado: EventEmitter<EventoPGRModel> = new EventEmitter();

  // se for evento composto, esse output será acionado
  @Output() eventoCompostoSelecionadoEvt: EventEmitter<EventoPGRModel[]> = new EventEmitter();

  constructor(private snackbarService: SnackbarService, private loadingService: LoadingService, private pgrService: PGRService) {
    this.buscarEventos();
  }

  // lista de eventos disponiveis para criar os eventos compostos
  listaEventos: EventoPGRModel[];// = EVENTOS;

  // eventos selecionados q farão parte do evento composto
  eventosSelecionados: EventoPGRModel[] = [];

  selecionarEvento(eventoSelecionado: any, mapIndex: number) {

    if(this.eventoComposto == false) {
      this.eventoSelecionado.emit(eventoSelecionado);
    } else {
      if(this.checkboxMap[mapIndex] === false) {

        const arrayIndex = this.eventosSelecionados.findIndex((e: EventoPGRModel) => e._id == eventoSelecionado._id);
        if(arrayIndex != -1)
        this.eventosSelecionados.splice(arrayIndex, 1);
      } else {
      
        if(this.eventosSelecionados.length == 2) {
          this.snackbarService.openSnackBar('Um evento composto pode conter apenas 2 eventos');
          return;
        }

        this.eventosSelecionados.push(eventoSelecionado);
      }
      
    }
  }
  
  eventoCompostoCriado() {
    this.eventoCompostoSelecionadoEvt.emit(this.eventosSelecionados);
    this.eventosSelecionados = [];
    this.updateCheckboxMap(false);
  }

  private updateCheckboxMap(newValue: boolean) {
    this.listaEventos.forEach( (e,i) => {
      this.checkboxMap[i] = newValue;
    });
  }

  buscarEventos(): void {
    this.loadingService.showLoading(true, 'Buscando eventos... Por favor, aguarde!');
    this.pgrService.listarEventosPGR().subscribe({
      next: (result) => {
        this.listaEventos = result;
        this.loadingService.showLoading(false);
        this.updateCheckboxMap(false);
      }, error: (err) => {
        this.snackbarService.openSnackBar(' Erro ao buscar eventos.', 'Close', 'error-snackbar');
        this.loadingService.showLoading(false);
      }
    });
  }
}
