<mat-dialog-content class="mat-typography">
    <mat-card>
        <mat-card-header>
            <mat-card-title-group>
                <mat-card-title>Dados da Rota - {{getNomeRota()}}</mat-card-title>
            </mat-card-title-group>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="form" style="margin-top: 1em;">
                <div class="grid">
                    <div class="col-3 sm:col-12 md:col-5">
                        <mat-form-field appearance="outline">
                          <mat-label>Cliente Transportador</mat-label>
                          <mat-select formControlName="clienteTransportadorId" (selectionChange)="preencheValorGerenciador($event)">
                            <mat-option *ngFor="let clienteTransportador of clientesTransportador" [value]="clienteTransportador._id">
                              {{ clienteTransportador.razaoSocial }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3 sm:col-12 md:col-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Código:</mat-label>
                            <input matInput type="text" formControlName="nome" [disabled]="true" readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-6 sm:col-12 md:col-5">
                        <mat-form-field appearance="outline" >
                            <mat-label>Descrição:</mat-label>
                            <input matInput type="text" required formControlName="descricao">
                            <mat-error>Campo obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 sm:col-12 md:col-2">
                        <mat-form-field appearance="outline" >
                            <mat-label>Distância total:</mat-label>
                            <input matInput type="text" formControlName="distancia" readonly="true">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card style="margin-top: 1em;">
        <mat-card-header>
            <mat-card-title-group>
                <mat-card-title>Rota</mat-card-title>
            </mat-card-title-group>
        </mat-card-header>

        <mat-card-content>
            <div class="grid">
                <div class="col-12 sm:col-12 md:col-3 lg:col-3">
                    <h5 style="margin-top: unset !important;">Escolha os pontos de parada da sua rota e clique em Traçar Rota</h5>

                    <!-- <app-autocomplete-pontos-rota
                        [desabilitarControles]="this.rotaAlternativa"
                        (addMarker)="addMarker($event)" (removeMarker)="removeMarker($event)" [pontoParadaRotaList]="this.rota.pontoParadaRota">
                    </app-autocomplete-pontos-rota> -->

                    <div class="grid">

                        <div class="col-12 ">
                            <app-busca-cep *ngFor="let pontoParada of this.rota.pontoParadaRota;let i = index"
                                (localSelecionado)="localSelecionado($event)"
                                (localRemovido)="localRemovido($event)"
                                (localAdicionado)="localAdicionado($event)"
                                [index]="i"
                                [dadosEnderecoModel]="getDadosEnderecoPontoParada(pontoParada)"
                                [disableControls]="this.editing">
                            </app-busca-cep>
                        </div>


                        <div class="col-12" *ngIf="this.rotaAlternativa == false && this.editing == false" style="text-align: center;">
                            <button mat-raised-button color="accent" (click)="gerarRota(true)">
                                <mat-icon>route</mat-icon>Traçar rota
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-12 md:col-9 lg:col-9">
                    <app-google-maps
                        [verticesList]="polylineList"
                        [markerOptions]="markerOptions"
                        (rotaGerada)="rotaGerada($event || null)"
                        (mapComponentLoaded)="mapComponentLoaded($event)">
                    </app-google-maps>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="botoes-cadastro-form">
        <button mat-raised-button color="warn" mat-dialog-close="">Cancelar</button>
        <ng-container *appHasWritePermission="'rota'">
          <button mat-raised-button color="primary" *ngIf="!rotaAlternativa" (click)="salvar()"  [disabled]="this.desabilitarGravar">Gravar</button>
          <button mat-raised-button color="primary" *ngIf="rotaAlternativa" (click)="salvarRotaAlternativa()"  [disabled]="this.desabilitarGravar">Gravar Rota Alternativa</button>
        </ng-container>
    </div>
</mat-dialog-content>
