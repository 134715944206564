export const environment = {
  production: false,
  hostMonitoramentoURL: 'https://api-hml.ecotechlog.com.br/api/monitoramento-api',
  hostMensagemApi: 'https://api-hml.ecotechlog.com.br/api/envio-mensagem-api',
  hostLoginURL: 'https://api-hml.ecotechlog.com.br/api/auth',

  ecotech: {
    auth: {
      login: 'login-usuario-ecotech'
    },
    usuarioGerenciadorRisco: {
      postNovoUsuarioGerenciadorRisco: 'ecotech/gravar-usuario-gerenciador-risco',
      getUsuariosGerenciadorRisco: 'ecotech/listar-usuario-gerenciador-risco'
    },
    funcionalidade: {
      listarFuncionalidades: 'funcionalidade/listar-funcionalidades'
    }
  },

  gerenciadorRisco: {
    auth: {
      login: 'login-usuario-gerenciador'
    },
    pontoParada: {
      list: 'ponto-parada/list',
      save: 'ponto-parada/save',
      delete: ''
    },
    areaRisco: {
      list: 'area-risco/list',
      listByCodigo: 'area-risco/list-by-codigo',
      save: 'area-risco/save',
      verificar: 'area-risco/verificar-localizacao',
      delete: 'area-risco/delete',
      deleteById: 'area-risco/delete-by-id',
      importar: 'area-risco/importar',
      verificarUsoEmPgr: 'area-risco/get-pgr',
      alterarStatusAreaRisco: 'area-risco/alterar-status-area-risco'
    },
    rota: {
      salvar: 'rota/gravar-rota',
      verificarRotaVeiculo: 'rota/verificar-rota-veiculo',
      listarRotas: 'rota/listar-rotas',
      buscarRota: 'rota/buscar-rota',
      buscarRotaPorId: 'rota/buscar-rota-id',
      alterarStatusRota: 'rota/alterar-status-rota',
    },
    relatorios: {
      totalPortipoEvento: 'relatorio/total-por-tipoevento',
      totalSmAprovadaPorDia: 'relatorio/aprovacao-sm-pordia',
      totalHorasSM: 'relatorio/total-horas-sm',
      eventosXls: 'relatorio/exportar-eventos-xls',
      historicoMonitoramentoXls: 'relatorio/exportar-historico-monitoramento-xls',
      listarHistoricoMonitoramento: 'relatorio/lista-historico-monitoramento',
      historicoMensagemWhatsapp: 'relatorio/historico-mensagens-whatsapp',
      historicoMensagemWhatsappXLS: 'relatorio/historico-mensagens-whatsapp-xls',
      relatorioGerencialSM: 'relatorio/gerencial-sm',
      relatorioGerencialSMXls: 'relatorio/gerencial-sm-xls',
    },
    rastreio: {
      listarRastreioPorEstacao: 'monitoramento/listar-rastreios-estacao',
    },
    veiculo: {
      localizar: 'veiculo/localizar'
    },
    frota: {
      listar: 'veiculo/listar-veiculos',
      atualizarTodos: 'telematica/atualiza-posicao-veiculos'
    },
    integracao: {
      getDadosMotorista: 'gerenciador-risco-integracao/dados-motorista',
      w4: 'gerenciador-risco-integracao/w4-sm'
    },
    mensagens: {
      buscarTodas: 'mensagem-monitoramento/by-monitoramento-id',
      marcarComoLida: 'mensagem-monitoramento/marcar-lida',
      marcarComoNaoLida: 'mensagem-monitoramento/marcar-nao-lida',
      buscarTodasNaoLidas: 'mensagem-monitoramento/listar-nao-lidas'
    }
  },

  transportador: {
    auth: {
      login: 'login-usuario-transportador'
    },
    pontoParada: {
      list: '',
      save: '',
      delete: ''
    },
    areaRisco: {
      list: '',
      save: '',
      delete: ''
    }
  },

  autotrac: {
    getTodasContas: 'autotrac/recupera-contas',
    recuperaHistoricoPosicoes: 'autotrac/recupera-historico-posicoes',
    getTodosVeiculosConta: 'autotrac/recupera-veiculos'
  },

  cadastro: {
    motorista: {
      postNovoMotorista: 'motorista/gravar-motorista',
      getMotoristas: 'motorista/listar-motoristas',
      getTodosMotoristas: 'motorista/listar-motoristas-sem-paginacao',
      getMotoristasPorNome: 'motorista/listar-motoristas-por-nome',
      alterarStatusMotorista: 'motorista/alterar-status-motorista'

    },

    veiculo: {
      postNovoVeiculo: 'veiculo/gravar-veiculo',
      getVeiculos: 'veiculo/listar-veiculos',
      getVeiculosMonitoramento: 'veiculo/listar-veiculos-monitoramento'
    },

    veiculoTransportadora: {
      postNovoVeiculo: 'veiculo-transportadora/gravar-veiculo',
      getVeiculosTransportadoraMonitoramento: 'veiculo-transportadora/listar-veiculos-monitoramento',
      getVeiculos: 'veiculo-transportadora/listar-veiculos',
      alterarStatus: 'veiculo-transportadora/alterar-status'
    },

    carretaTransportadora: {
      postNovaCarreta: 'carreta-transportadora/gravar-carreta',
      getCarretas: 'carreta-transportadora/listar-carretas',
      alterarStatus: 'carreta-transportadora/alterar-status',
      pesquisar: 'carreta-transportadora/pesquisar'
    },
    seguradora: {
      postNovaSeguradora: 'seguradora-transportadora/gravar-seguradora',
      getSeguradoras: 'seguradora-transportadora/listar-seguradoras',
      alterarStatus: 'seguradora-transportadora/alterar-status',
      pesquisar: 'seguradora-transportadora/pesquisar'
    },
    cliente: {
      postNovoCliente: 'cliente/gravar-cliente',
      getClientes: 'cliente/listar-clientes',
    },

    monitoramento: {
      postNovoMonitoramento: 'monitoramento/gravar-monitoramento',
      getMonitoramentos: 'monitoramento/listar-monitoramentos',
      getMonitoramentoById: 'monitoramento/recupera-monitoramento-por-id',
      listarObservacoes: 'observacoes-monitoramento/listar',
      salvarObservacao: 'observacoes-monitoramento/gravar'
    },

    gerenciadorRisco: {
      postNovoGerenciadorRisco: 'cliente-gerenciador-risco/gravar-cliente-gerenciador',
      getGerenciadoresRisco: 'cliente-gerenciador-risco/listar-cliente-gerenciador',
      getListGerenciadores: 'cliente-gerenciador-risco/listar-cliente-gerenciador-list'
    },

    usuarioGerenciadorRisco: {
      postNovoUsuarioGerenciadorRisco: 'usuario-cliente/gravar-usuario-gerenciador-risco',
      getUsuariosGerenciadorRisco: 'usuario-cliente/listar-usuario-gerenciador-risco',
      alterarStatusUsuarioGerenciadorRisco: 'usuario-cliente/alterar-status-cliente-gerenciador'
    },

    usuarioClienteTransportadorIntegracao: {
      postNovoUsuario: 'usuario-cliente-integracao/cadastrar-integracao-cliente-transportador',
      pesquisar: 'usuario-cliente-integracao/listar-integracao-cliente-transportador',
      alterarStatusTransportadorIntegracao: 'usuario-cliente-integracao/alterar-status-cliente-integracao'
    },

    clienteTransportador: {
      postNovoClienteTransportador: 'cliente-transportador/gravar-cliente-transportador',
      getClientesTransportador: 'cliente-transportador/listar-clientes-transportador',
      getClientesTransportadorList: 'cliente-transportador/listar-todos-transportadores',
      getClientesTransportadorMatrizByRazaoSocial: 'cliente-transportador/listar-clientes-transportador-matriz',
      getTodosTransportadoresFiliais: 'cliente-transportador/listar-transportadores-filiais',
      getTransportadorasMatriz: 'cliente-transportador/listar-transportador-matriz',
      getTodosTransportadorEmbarcador: 'cliente-transportador/listar-transportador-embarcador',
      alterarStatusClienteTransportador: 'cliente-transportador/alterar-status-cliente-transportador'
    },

    contaRastreio: {
      getContasRastreio: 'conta-rastreio/listar-contas',
    },

    usuarioClienteTransportador: {
      postNovoUsuarioClienteTransportador: 'usuario-cliente-transportador/gravar-usuario-cliente',
      getUsuariosClienteTransportador: 'usuario-cliente-transportador/listar-usuarios-clientes',
      alterarStatusClienteTransportador: 'usuario-cliente-transportador/alterar-status-usuario-transportador'
    },

    estacao: {
      postNovaEstacao: 'estacao/gravar-estacao',
      getEstacoes: 'estacao/listar-estacoes',
      listarTodasEstacoes: 'estacao/listar-todas-estacoes',
      alterarStatusEstacao: 'estacao/alterar-status-estacao',
      getEstacoesIds: 'estacao/listar-idsEstacoes-usuarioGerenciador'
    },

    pgr: {
      postNovoPGR: 'pgr/gravar-pgr',
      getPGRByDesc: 'pgr/listar-pgrs',
      getPGRs: 'pgr/listar-pgrs-paginado',
      alerarStatusPGR: 'pgr/alterar-status-pgr'
    },
    eventoPGR:{
      listarEventoPGR: 'evento-pgr/listar-eventos-pgr',
    },

    contaRastreioCliente:{
      postNovaContaRastreioCliente: 'conta-rastreio-cliente/gravar-conta-rastreio-cliente',
      getContasRastreioCliente: 'conta-rastreio-cliente/listar-conta-rastreio-cliente',
      ativarContaRastreioCliente: 'conta-rastreio-cliente/ativar-conta-rastreio-cliente',
      atualizaStatusContaRastreioCliente: 'conta-rastreio-cliente/atualiza-status-conta'
    },

    minhaFrota: {
      veiculos: 'minha-frota/listar-veiculos'
    },
    tipoProduto: {
      postNovoTipoProduto: 'tipo-produto/gravar-tipo-produto',
      getTipoProduto: 'tipo-produto/listar-tipo-produto',
      getTodosTipoProduto: 'tipo-produto/listar-tipo-produto-sem-paginacao',
      getTipoProdutoPorNome: 'tipo-produto/listar-tipo-produto-por-nome',
      alterarStatusTipoProduto: 'tipo-produto/alterar-status-tipo-produto',
      pesquisar: 'tipo-produto/pesquisar'

    },
    produto: {
      postNovoProduto: 'produto/gravar-produto',
      getProduto: 'produto/listar-produto',
      getTodosProduto: 'produto/listar-produto-sem-paginacao',
      getProdutoPorNome: 'produto/listar-produto-por-nome',
      alterarStatusProduto: 'produto/alterar-status-produto',
      pesquisar: 'produto/pesquisar'
    },
    contato: {
      gravar: 'contato/gravar',
      pesquisar: 'contato/pesquisar',
      alterarStatus: 'contato/alterar-status',
    },
    grupoContato: {
      gravar: 'grupo-contato/gravar-grupo',
      pesquisar: 'grupo-contato/listar-grupo',
      alterarStatus: 'grupo-contato/alterar-status',
      pesquisarInfoBasicas: 'grupo-contato/listar-grupo-info',
    },
    templateMensagem: {
      gravar: 'template-mensagem/gravar',
      pesquisar: 'template-mensagem/listar',
      alterarStatus: 'template-mensagem/alterar-status',
    },
    envioWhatsapp: {
      testar: 'migracao-tecnologia/simular-pgr'
    }
  },

  acoes:{
    logMonitoramento: {
      getLogMonitoramento: 'log-monitoramento/recupera-logs-monitoramento'
    },

    monitoramento: {
      aprovarMonitoramento: 'monitoramento/aprovar-rastreio',
      cancelarMonitoramento: 'monitoramento/cancelar-rastreio',
      reprovarMonitoramento: 'monitoramento/reprovar-rastreio',
      atualizarRastreios: 'monitoramento/atualizar-rastreios-ativos',
      autorizarVeiculo: 'monitoramento/autorizar-veiculo',
      finalizarRastreio: 'monitoramento/finalizar-rastreio',
      getViagensFinalizadas: 'monitoramento/listar-rastreios-finalizados',
      getHistoricoMacros: 'monitoramento/listar-historico-macros',
      getMacrosEnvio: 'monitoramento/listar-macros-envio',
      getRotas: 'monitoramento/get-rota-prevista-realizada',
      reativarSM: 'monitoramento/reativar-rastreio',
      gravarRotaAlternativa: 'monitoramento/gravar-rota-alternativa',
      excluirRotaAlternativa: 'monitoramento/excluir-rota-alternativa',
    },

    monitorar: {
      getRastreiosAtivos: 'monitoramento/listar-rastreios-ativos',
      getEventosAbertos: 'evento-tratativa/listar-eventos-abertos',
      listarInibicoesAtivas: 'eventos-inibidos/listar-inibicoes-ativas',
      getTotalizadores: 'monitoramento/totalizadores-monitoramento',
      verificarVeiculoAtivo: 'monitoramento/verificar-veiculo-ativo'
    },

    eventoTratativa: {
      getEventoTratativaById: 'evento-tratativa/recuperar-evento-tratativa',
      getEventosPorMonitoramento: 'evento-tratativa/buscar-eventos',
      atualizarTratativa: 'evento-tratativa/atualizar-tratativa',
      recuperaHistoricoTratativaEvento: 'evento-tratativa/historico-tratativas',
      verificaPossuiEventoNaoTratado: 'evento-tratativa/possui-eventos-nao-tratado',
      tratarEmLote: 'evento-tratativa/atualizar-tratativa-lote',
      cancelarInibicao: 'evento-tratativa/cancelar-inibicao',
      prolongarInibicao: 'evento-tratativa/prolongar-inibicao',
      populaCardsEventoMonitoramento: 'evento-tratativa/cards-monitoramento'
    },

    eventosInibidos: {
      getEventosInibidosPorMonitoramento: 'eventos-inibidos/recupera-por-monitoramento-id',
      cancelarInibicao: 'eventos-inibidos/cancelar-inibicao',
      prolongarInibicao: 'eventos-inibidos/prolongar-inibicao',
    },

    migracao: {
      atualizaContaVeiculo: 'migracao-tecnologia/atualiza-contas-veiculos',
    },
  }
};

