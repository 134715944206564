<!-- FDZ5519 -->
<h1 mat-dialog-title>
  Rota do Veículo {{this.data.placa}}
</h1>
<!-- <h3 mat-dialog-title>
  Rotas Alternativas

  <ng-container *ngIf="this.data.rotaAlternativaList && this.data.rotaAlternativaList.length;else nenhumaRotaAlternarivaCadastrada">
    <span *ngFor="let rotaAlternativa of this.data.rotaAlternativaList;let i = index;">
      Rota alternativa {{i}}
    </span>
  </ng-container>
  <ng-template #nenhumaRotaAlternarivaCadastrada>
    <h6>Nenhuma rota alternativa cadastrada para esse monitoramento</h6>
  </ng-template>
</h3> -->
<div mat-dialog-content>
  <div class="legend-container">
    <div class="flex" > <div class="square blue"></div>Rota Prevista</div>
    <div class="flex" > <div class="square orange"></div>Rota Realizada</div>
    <div class="flex" > <div class="square grey"></div>Rota Alternativa</div>
</div>
<app-google-maps [verticesList]="verticesList" (mapComponentLoaded)="mapComponentLoaded($event)"></app-google-maps>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Fechar</button>
  <ng-container *appHasWritePermission="'rastreio-veiculo'">
    <button *ngIf="this.existeRotaAlternativa" mat-raised-button color="warn" (click)="removerRotaAlternativa()" >Remover rota alternativa</button>
    <button *ngIf="!this.existeRotaAlternativa" mat-raised-button color="primary" (click)="adicionarRotaAlternativa()" >Adicionar rota alternativa</button>
  </ng-container>

</div>
