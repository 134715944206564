import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AcaoParentModel } from '../../model/evento-pgr-model';
import { SnackbarService } from '../../service/snackbar.service';
import { AcoesEnum } from '../../enum/acoes-enum';
import { GrupoContatoTemplateComponent } from '../grupo-contato-template/grupo-contato-template.component';
import { NotificacaoMensagemModel } from '../../model/notificacao-mensagem-model';

@Component({
  selector: 'app-selecao-acoes',
  templateUrl: './selecao-acoes.component.html',
  styleUrls: ['./selecao-acoes.component.scss']
})
export class SelecaoAcoesComponent {

  ACAO_ENVIO_WHATSAPP_ENUM = AcoesEnum.ENVIO_WHATSAPP

  constructor(
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<SelecaoAcoesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      itensDisponiveis: AcaoParentModel[],
      itensSelecionados: AcaoParentModel[],
      titulo: string
    },
    private dialog: MatDialog
    ) {
      console.log(this.data)
  }

  evento: string = 'Teste';

  selecionar(itemSelecionado: AcaoParentModel) {
    const index: number = this.data.itensSelecionados.findIndex(item => item.nome == itemSelecionado.nome);
    if(index != -1) {
      this.snackbarService.openSnackBar('Ação já selecionada','','');
      return;
    }
    this.data.itensSelecionados.push(itemSelecionado);
  }
  
  remover(itemSelecionado: AcaoParentModel) {
    const index: number = this.data.itensSelecionados.findIndex(item => item.nome == itemSelecionado.nome);
    this.data.itensSelecionados.splice(index, 1);
  }

  showBtn(nome: string) {
    return this.data.itensSelecionados.findIndex(item => item.nome == nome) == -1;
  }

  fechar() {
    this.dialogRef.close(this.data.itensSelecionados);
  }

  configurarGrupos(itemSelecionado: any) {
    console.log(itemSelecionado);

    const dialogRef = this.dialog.open(GrupoContatoTemplateComponent, {
      minWidth: '50%',
      minHeight: '50%',
      data: {
        titulo: 'Atenção',
        subtitulo: `Tem certeza que deseja remover todas as geometrias do mapa?`}
    });

    dialogRef.afterClosed().subscribe((notificacaoMensagem: NotificacaoMensagemModel[]) => {
      if(notificacaoMensagem) {
        
      }
    });
  }
}
