<h1 mat-dialog-title>{{this.data.titulo}}</h1>

<mat-dialog-content style="margin-top: 1em;">  
  <mat-grid-list cols="2">
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Notificações selecionadas</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="this.data.itensSelecionados.length > 0; else semItemSelecionado">
          <mat-selection-list >
            <mat-list-item role="listitem" *ngFor="let item of this.data.itensSelecionados">
              <span class="flex align-items-center justify-content-between">
                <div class="flex align-items-center">
                  <button mat-raised-button color="warn" (click)="remover(item)">Remover</button>
                  <div class="descricao-item">{{item.descricao}}</div>
                </div>
                <!-- Continuar daqui, botao para selecionar mais de um grupo e um template -->
                <div *ngIf="item.nome == ACAO_ENVIO_WHATSAPP_ENUM">
                  <button mat-raised-button color="default" (click)="configurarGrupos(item)">Configurar grupos</button>
                </div>
              </span>
            </mat-list-item>
          </mat-selection-list>
        </ng-container>
        <ng-template #semItemSelecionado>
          <mat-selection-list>
            <mat-list-item role="listitem">
              Nenhuma notificação selecionada
            </mat-list-item>
          </mat-selection-list>
        </ng-template>
      </mat-card>
      
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Tipos de notificações disponíveis</mat-card-title>
        </mat-card-header>
        <mat-selection-list #disponiveis>
          <mat-list-item role="listitem" *ngFor="let item of this.data.itensDisponiveis">
            <span class="flex align-items-center">
              <button class="adicionar" mat-raised-button color="primary" (click)="selecionar(item)" *ngIf="showBtn(item.nome) == true; else adicionado">Adicionar</button>
              <ng-template #adicionado>
                <button class="adicionado" mat-stroked-button color="primary">Adicionado</button>
              </ng-template>
              <p class="descricao-item">{{item.descricao}}</p>
            </span>
          </mat-list-item>
        </mat-selection-list>      
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  
</mat-dialog-content>



<div mat-dialog-actions>
  <button mat-button (click)="fechar()">Cancelar</button>
  <button mat-button cdkFocusInitial (click)="fechar()">Ok</button>
</div>
  