import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapApiService {

  public apiLoaded: Observable<boolean> = of(false);

  constructor() { }
}
