import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { ClienteTransportadorModel } from 'src/app/core/model/cliente-transportador-model';
import { ParentService } from 'src/app/core/service/parent.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteTransportadorService extends ParentService {
  private todosTransportadoresListCache: Observable<ClienteTransportadorModel[]>;
  private todosTransportadoresFiliaisListCache: Observable<ClienteTransportadorModel[]>;
  private todosTransportadoresMatrizesListCache: Observable<ClienteTransportadorModel[]>;
  private cacheTime: Date;
  private cacheExpiry = 300000; // Five minutes

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  buscarClienteTransportador(page: number = 0, size: number = 5, cnpj: string, razaoSocial: string, tipoCliente: string) {

    let params =   new HttpParams();

    if (cnpj) {
      params = params.append('cnpjClienteTransportador', cnpj);
    }

    if (razaoSocial) {
      params = params.append('razaoSocial', razaoSocial);
    }

    if(tipoCliente) {
      params = params.append('tipoCliente', tipoCliente)
    }

    const url = `${environment.cadastro.clienteTransportador.getClientesTransportador}?page=${page}&size=${size}`;
    return super.get(url, params);
  }

  buscarTodosTransportadores(): Observable<any> {
    this.refreshCacheTodosTransportadoresList();

    if (!this.todosTransportadoresListCache) {
      const url = `${environment.cadastro.clienteTransportador.getClientesTransportadorList}`;

      this.todosTransportadoresListCache = super.get(url).pipe(shareReplay(1));
      this.cacheTime = new Date();
    }

    return this.todosTransportadoresListCache;
  }

  buscarTodosTransportadoresMatriz(){
    this.refreshCacheTodosTransportadoresMatrizesList();

    if (!this.todosTransportadoresMatrizesListCache) {
      const url = `${environment.cadastro.clienteTransportador.getTransportadorasMatriz}`;

      this.todosTransportadoresMatrizesListCache = super.get(url).pipe(shareReplay(1));
      this.cacheTime = new Date();
    }

    return this.todosTransportadoresMatrizesListCache;
  }

  gravarClienteTransportador(clienteTransportador: ClienteTransportadorModel){
    const url = `${environment.cadastro.clienteTransportador.postNovoClienteTransportador}`;
    return super.post(url, clienteTransportador);
  }

  listarClienteTransportadorMatrizByRazaoSocial(razaoSocial: any) {
    const url = `${environment.cadastro.clienteTransportador.getClientesTransportadorMatrizByRazaoSocial}?razaoSocial=${razaoSocial}`;
    return super.get(url);
  }

  listarTodasTransportadorasFiliaisByName(nome: string){
    const url = `${environment.cadastro.clienteTransportador.getTodosTransportadoresFiliais}/${nome}`;
    return super.get(url);
  }

  listarTodasTransportadorasEmbarcadorByName(nome: string){
    const url = `${environment.cadastro.clienteTransportador.getTodosTransportadorEmbarcador}/${nome}`;
    return super.get(url);
  }

  listarTodasTransportadorasFiliais() {
    this.refreshCacheTodosTransportadoresFiliaisList();

    if (!this.todosTransportadoresFiliaisListCache) {
      const url = `${environment.cadastro.clienteTransportador.getTodosTransportadoresFiliais}`;

      this.todosTransportadoresFiliaisListCache = super.get(url).pipe(shareReplay(1));
      this.cacheTime = new Date();
    }

    const url = `${environment.cadastro.clienteTransportador.getTodosTransportadoresFiliais}`;
    return super.get(url);
  }

  refreshCacheTodosTransportadoresList(): void {
    const now = new Date();
    if (this.cacheTime && (now.getTime() - this.cacheTime.getTime()) > this.cacheExpiry) {
      this.todosTransportadoresListCache = null;
      this.cacheTime = null;
    }
  }

  refreshCacheTodosTransportadoresFiliaisList(): void {
    const now = new Date();
    if (this.cacheTime && (now.getTime() - this.cacheTime.getTime()) > this.cacheExpiry) {
      this.todosTransportadoresFiliaisListCache = null;
      this.cacheTime = null;
    }
  }

  refreshCacheTodosTransportadoresMatrizesList(): void {
    const now = new Date();
    if (this.cacheTime && (now.getTime() - this.cacheTime.getTime()) > this.cacheExpiry) {
      this.todosTransportadoresMatrizesListCache = null;
      this.cacheTime = null;
    }
  }

  alterarStatusClienteTransportador(clienteTransportadorId: string){
    const url = `${environment.cadastro.clienteTransportador.alterarStatusClienteTransportador}?clienteTransportadorId=${clienteTransportadorId}`;
    return super.get(url);
  }

}
