import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './core/service/interceptor.service';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CoreComponentsModule } from './core/component/core-components.module';
import { EventoPgrPipe } from './core/pipes/eventos.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';

registerLocaleData(localePt)

@NgModule({
  declarations: [
    AppComponent,
    EventoPgrPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreComponentsModule,
    MatToolbarModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    MatSnackBarModule,
    NoopAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
