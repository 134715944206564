import { NotificacaoMensagemModel } from "./notificacao-mensagem-model";

export class EventoPGRModel {
  _id: string;
  idDinamico?: number;
  nomeEvento: string;
  descricaoEvento: string;
  labelFiltro: string;
  config?: {
    pipe: string,
    options?: {label: string, value: string}[]
  };
  hasExtraInfo: boolean;
  hasSMInfo?: boolean;
  extraInfo: any;
  eventosInibidosIdsDestino?: string[] = [];
  eventosInibidosIdsOrigem?: string[] = [];
  eventosInibidosIdsPontoDeEntrega?: string[] = [];
}
export class AcaoParentModel {
  _id: string;
  nome: string;
  descricao: string;
  label: string;

  notificacoes: NotificacaoMensagemModel[];
}
export class AcaoGerenciadorModel extends AcaoParentModel {

}
export class ComandoTecnologiaModel extends AcaoParentModel {

}
export class AlertaVisuaisModel extends AcaoParentModel {

}
