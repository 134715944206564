<h2 mat-dialog-title>Espelhar Veículos</h2>
<mat-dialog-content class="mat-typography">
    <div class="grid">
        <div class="col-12">
            Cliente: {{this.data.clienteTransportador.razaoSocial}}
        </div>
        <div class="col-12">
          <mat-form-field class="form_monitoramento" appearance="outline">
            <mat-label>Conta a ser espelhada:</mat-label>
            <mat-select [(ngModel)]="contaRastreio">
              <mat-option *ngFor="let objeto of this.data.clienteTransportador.contaRastreioClienteList" [value]="objeto">{{tecnologiaRastreioPipe.transform(objeto.tecnologiaRastreio)}} - {{objeto.usuario}}</mat-option>
            </mat-select>
            <mat-error>Selecione a Tecnologia de Rastreio!</mat-error>
          </mat-form-field>
        </div>
      </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="confirmarEspelhamento()">Espelhar</button>
</mat-dialog-actions>
