<h1 mat-dialog-title>Observacoes - {{this.data.monitoramento.placa}}</h1>
<div mat-dialog-content>
    <div class="grid">
        <div class="col-5">
            <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Filtrar</mat-label>
                <input type="text" matInput [(ngModel)]="filtro">
            </mat-form-field>
        </div>
        <div class="col-5">
            <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Inicio</mat-label>
                <mat-date-range-input [rangePicker]="picker1">
                  <input matStartDate [(ngModel)]="inicio" placeholder="Data de início">
                  <input matEndDate [(ngModel)]="fim" placeholder="Data de fim">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-date-range-picker #picker1></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="col-2" style="text-align: center;">
            <button mat-raised-button color="primary" (click)="listarObservacoesSm(this.pageEvent)" class="ml-auto" style="margin-right: 10px;">
                <mat-icon>search</mat-icon>Pesquisar
            </button>
        </div>
    </div>
    <mat-list>
        <mat-list-item *ngFor="let observacao of observacoes;let i = index" lines="3" >
            <span matListItemTitle>
                {{observacao.descricao}}
            </span>
            <span class="autor">
                <b>{{observacao.userName}}</b> em <b>{{observacao.createdAt | date:'dd/MM/yyyy HH:mm:ss'}}</b>
            </span>
        </mat-list-item>
        <div style="padding: 0em 1em;">
            <app-paginator [pageEvent]="pageEvent" (pageEventFired)="listarObservacoesSm($event)"></app-paginator>
        </div>
    </mat-list>

    <div class="textarea-content">
        <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Nova observacao</mat-label>

            <input type="textarea" matInput [(ngModel)]="novaObservacao" class="input-obs">

            <button mat-mini-fab color="primary" *appHasWritePermission="'rastreio-veiculo'" (click)="enviarNovaObservacao()" >
                <mat-icon>send</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Fechar</button>
</div>
