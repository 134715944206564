import { EventoPGRModel } from "src/app/core/model/evento-pgr-model";

export const EVENTOS: EventoPGRModel[] = [{
    "_id": "1",
    "idDinamico": undefined,
    "nomeEvento": "EVENTO_LIMITE_VELOCIDADE",
    "descricaoEvento": "Evento de limite de velocidade",
    "labelFiltro": "km/h",
    "config": {
        "pipe": "number"
    },
    hasExtraInfo: false,
    extraInfo: {}
},{
    "_id": "2",
    "idDinamico": undefined,
    "nomeEvento": "EVENTO_PARADA_PROLONGADA",
    "descricaoEvento": "Evento de tempo de parada prolongada",
    "labelFiltro": "minutos",
    "config": {
        "pipe": "number"
    },
    hasExtraInfo: false,
    extraInfo: {}
},{
    "_id": "3",
    "idDinamico": undefined,
    "nomeEvento": "EVENTO_DESVIO_ROTA",
    "descricaoEvento": "Evento de desvio de rota",
    "labelFiltro": "metros",
    "config": {
        "pipe": "number"
    },
    hasExtraInfo: false,
    extraInfo: {}
}
// ,{
//     "_id": "4",
//     "idDinamico": undefined,
//     "nomeEvento": "VIOLACAO_PAINEL",
//     "descricaoEvento": "Painel violado",
//     "labelFiltro": "Violação de Painel",
//     "config": {
//         "pipe": "boolean",
//         "options": [{label: "Sim", value: "true"},{label: "Não", value: "false"}]
//     }
// },{
//     "_id": "5",
//     "idDinamico": undefined,
//     "nomeEvento": "SENSOR_BATERIA",
//     "descricaoEvento": "Bateria com baixa energia",
//     "labelFiltro": "Sensor da Bateria",
//     "config": {
//         "pipe": "number"
//     }
// },{
//     "_id": "6",
//     "idDinamico": undefined,
//     "nomeEvento": "TENSAO_BATERIA",
//     "descricaoEvento": "Bateria com baixa tensão",
//     "labelFiltro": "Tensão da Bateria",
//     "config": {
//         "pipe": "number"
//     }
// },{
//     "_id": "7",
//     "idDinamico": undefined,
//     "nomeEvento": "BOTAO_PANICO",
//     "descricaoEvento": "Botão de pânico acionado",
//     "labelFiltro": "Botão de Pânico",
//     "config": {
//         "pipe": "boolean",
//         "options": [{label: "Sim", value: "true"},{label: "Não", value: "false"}]
//     }
// },{
//     "_id": "8",
//     "idDinamico": undefined,
//     "nomeEvento": "AREA_RISCO",
//     "descricaoEvento": "Motorista entrou em área de risco",
//     "labelFiltro": "Área de Risco",
//     "config": {
//         "pipe": "boolean",
//         "options": [{label: "Sim", value: "true"},{label: "Não", value: "false"}]
//     }
// },{
//     "_id": "9",
//     "idDinamico": undefined,
//     "nomeEvento": "FRONTEIRA_BRASIL",
//     "descricaoEvento": "Motorista fora do território Brasileiro",
//     "labelFiltro": "Fronteira Brasileira",
//     "config": {
//         "pipe": "boolean",
//         "options": [{label: "Sim", value: "true"},{label: "Não", value: "false"}]
//     }
// },{
//     "_id": "10",
//     "idDinamico": undefined,
//     "nomeEvento": "JAMMER",
//     "descricaoEvento": "Jammer detectado",
//     "labelFiltro": "Jammer",
//     "config": {
//         "pipe": "boolean",
//         "options": [{label: "Sim", value: "true"},{label: "Não", value: "false"}]
//     }
// },{
//     "_id": "11",
//     "idDinamico": undefined,
//     "nomeEvento": "SINAL_OFFLINE",
//     "descricaoEvento": "Perda de sinal da tecnologia",
//     "labelFiltro": "Sinal Perdido",
//     "config": {
//         "pipe": "boolean",
//         "options": [{label: "Sim", value: "true"},{label: "Não", value: "false"}]
//     }
// }
];

export const ACOES = [{
    _id: "",
    "nome": "ENVIO_WHATSAPP",
    "descricao": "Notificar via whatsapp",
    "label": "Envio de Whatsapp"
}
// ,{
//     _id: "",
//     "nome": "ENVIO_EMAIL",
//     "descricao": "Notificar via email",
//     "label": "Envio de Email"
// },{
//     _id: "",
//     "nome": "ENVIO_SMS",
//     "descricao": "Notificar via sms",
//     "label": "Envio de Sms"
// },{
//     _id: "",
//     "nome": "ACIONAR_PR",
//     "descricao": "Acionar Pronta Resposta",
//     "label": "Acionar Pronta Resposta"
// }
];

export const COMANDOS_TECNOLOGIA = [{
    _id: "",
    "nome": "BLOQUEAR_VEICULO",
    "descricao": "Aciona o comando de bloqueio do veículo",
    "label": "Bloquear Veículo"
},{
    _id: "",
    "nome": "ACIONAR_SIRENE",
    "descricao": "Aciona a sirene do veículo",
    "label": "Acionar sirene"
}];

export const ALERTAS_VISUAIS = [{
    _id: "",
    "nome": "POPUP",
    "descricao": "Mostra um popup com informações sobre o evento",
    "label": "Mostrar Popup"
},{
    _id: "",
    "nome": "LINHA_VERMELHA",
    "descricao": "Altera a cor de fundo da SM para vermelho",
    "label": "Adicionar linha vermelha"
},{
    _id: "",
    "nome": "LINHA_AMARELA",
    "descricao": "Altera a cor de fundo da SM para amarelo",
    "label": "Adicionar linha amarela"
}];
