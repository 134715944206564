import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";

export function objectComparisonFunction(option: any, value: any): boolean {
    if(value && value._id && option && option._id) {
      return option.id === value.id;
    }

    return false;
}

export function objectComparisonFunction_id(option: any, value: any): boolean {
  if (value && value._id && option && option._id) {
    return option._id === value._id;
  }
return false;
}

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const controlDate = new Date(control.value);
    const currentDate = new Date();

    const forbidden = control.value && controlDate < currentDate;

    return forbidden ? {'forbiddenDate': {value: control.value}} : null;
  };
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

export function converterMinutosParaHoras(minutes: number): string {
  if (minutes < 60) {
      return `${minutes} m`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours} h ${remainingMinutes} m`;
}

export function getDiferencaEmMinutos(inicio: string, fim: string): number {
  if(!inicio || !fim) return 0;

  const diferenca:  number = new Date(fim).valueOf() - new Date(inicio).valueOf();
  const diferencaMinutos: number = (diferenca/1000)/60;
  return Math.trunc(diferencaMinutos*100)/100;
}