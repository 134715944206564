import { GrupoContatoModel } from "src/app/private/grupo-contato/grupo-contato-model";
import { TemplateMensagemModel } from "./template-mensagem-model";
import { TipoNotificacaoMensagemEnum } from "../enum/tipo-notificacao-mensagem-oficial-enum";

export class NotificacaoMensagemModel  {
    grupoContato: GrupoContatoModel;
    templateMensagem: TemplateMensagemModel;
    numeroAlertasParaNotificar: number;
    tipoNotificacao: string;
    isNotificacaoMensagemOficial: TipoNotificacaoMensagemEnum;
}
