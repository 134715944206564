<mat-dialog-content class="mat-typography">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Importar Arquivo</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="grid">
                <div class="col-6">
                    <mat-form-field class="form_monitoramento" appearance="outline">
                        <mat-label>Transportadoras:</mat-label>
                        <mat-select [(ngModel)]="transportadorId">
                            <mat-option *ngFor="let transportadora of transportadoraList" [value]="transportadora._id">{{transportadora.razaoSocial}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6" style="text-align: center;">
                    <button mat-raised-button class="botao-cadastrar mr-2" color="primary" (click)="fileInput.click()">
                        <mat-icon>upload</mat-icon>Selecionar Arquivo
                    </button>
                    <div *ngIf="nomeArquivo;else semSelecaoTmp" style="margin-top: 0.3em;text-align: center;">{{nomeArquivo}}</div>
                    <ng-template #semSelecaoTmp>
                        <div style="margin-top: 0.3em;text-align: center;font-weight: bold;">Nenhum arquivo selecionado</div>
                    </ng-template>
                    <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file">
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<div class="botoes-cadastro-form">
    <button mat-raised-button color="warn" mat-dialog-close="">Cancelar</button>
    <button mat-raised-button color="primary" (click)="importar()">Importar</button>
</div>