<h2 mat-dialog-title>Buscar CEP</h2>

<mat-dialog-content>
  <div class="grid" style="margin-top: 2em;">
    <div class="col-12 col-cep">
        <mat-form-field appearance="outline" class="mat-form-field-no-margin-bottom">
            <mat-label>CEP:</mat-label>
            <input 
              matInput 
              type="text" 
              mask="00000-000" 
              placeholder="Buscar por CEP" 
              [(ngModel)]="this.dadosEnderecoModel.cep" 
              (focus)="focus($event)">
        </mat-form-field>
        <button matSuffix mat-mini-fab color="primary" style="margin-left: 1em;" (click)="buscarCEP()">
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <div class="col-12" *ngIf="dadosEnderecoModel.enderecoCompleto">
        <h4 >
            Endereço: {{dadosEnderecoModel.enderecoCompleto}}<br/>
            <div style="margin-top: 1em;">
                LatLng: {{dadosEnderecoModel.latLng.lat + ", "+ dadosEnderecoModel.latLng.lng}}
            </div>
        </h4> 
        <mat-form-field appearance="outline" class="mat-form-field-no-margin-bottom">
          <input 
            matInput 
            type="text" 
            placeholder="Digite o número" 
            [(ngModel)]="this.dadosEnderecoModel.numero">
        </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="adicionar()">Adicionar</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
</mat-dialog-actions>