import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tecnologiaRastreioPipe'
})
export class TecnologiaRastreioPipe implements PipeTransform {

  tecnologiaMap: any = {
    "AT":"Autotrac",
    "BT":"Baita",
    "RS": "Rastro",
    "SC":"Sascar",
    "T4":"T4S",
    "TL":"Telematica",
    "ST":"STC",
    "MP":"Multi Portal",
    "OS":"Onixsat",
    "TR":"Trackn\'me",
    "OL":"Omnilink",
    "TRS":"Technorastro",
    "EMT": "EMTechApp",
    "MOB": "Mobis2"
  }
  transform(codigoTecnologia: string): string {
    return this.tecnologiaMap[codigoTecnologia];
  }
}
