import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventoPgrPipe'
})
export class EventoPgrPipe implements PipeTransform {

  eventoMap: any = {
    "EVENTO_DESVIO_ROTA":"Desvio de Rota",
    "EVENTO_PARADA_PROLONGADA":"Parada Prolongada",
    "EVENTO_LIMITE_VELOCIDADE":"Limite de Velocidade"
  }
  transform(codigoEvento: string): string {
    return this.eventoMap[codigoEvento];
  }
}
