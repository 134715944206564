import { PageEvent } from "@angular/material/paginator";

export class GrupoContatoPesquisaModel {

    constructor() {
    }

    transportadorIdList: string[] = [];
    status: string = 'T';
    nome: string;
    contatoId: string;
    page: number = 0;
    size: number = 10;
}