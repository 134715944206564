import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

  @Input() titulo: string = '';
  @Input() subtitulo: string = '';
  @Input() cancelText: string = 'Cancelar';
  @Input() okText: string = 'Sim';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if(data.titulo) {
      this.titulo = data.titulo;
    }
    if(data.subtitulo) {
      this.subtitulo = data.subtitulo;
    }
  }
}
