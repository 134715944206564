import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventoPGRModel } from 'src/app/core/model/evento-pgr-model';
import { PGRModel } from 'src/app/core/model/pgr-model';
import { PgrPesquisaModel } from 'src/app/core/model/pgr-pesquisa-model';
import { ParentService } from 'src/app/core/service/parent.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PGRService extends ParentService {

  buscarTodosPGRs(pesquisa:PgrPesquisaModel) {
    const params = new HttpParams({fromObject: pesquisa as any});
    const url = `${environment.cadastro.pgr.getPGRs}`;
    return super.get(url, params);

  }

  gravarPGR(pgr: PGRModel){
      const url = `${environment.cadastro.pgr.postNovoPGR}`;
      return super.post(url, pgr);
  }


  buscarPGRPorNome(nome: string, clienteTransportadorId: string){
    const url = `${environment.cadastro.pgr.getPGRByDesc}/${nome}?clienteTransportadorId=${clienteTransportadorId}`;
    return super.get(url);
  }

  listarEventosPGR(): Observable<EventoPGRModel[]>{
    const url = `${environment.cadastro.eventoPGR.listarEventoPGR}`;
    return super.get(url);
  }

  simular(): Observable<any>{
    const url = `${environment.cadastro.envioWhatsapp.testar}`;
    return super.get(url);
  }

  alterarStatusPGR(pgrId: string) {
    const url = `${environment.cadastro.pgr.alerarStatusPGR}?pgrId=${pgrId}`
    return super.get(url)

  }
}
