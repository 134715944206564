import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
    path: "",
    pathMatch: "full",
    redirectTo: "login"
  }, {
    path: 'login-ecotech',
    loadChildren: () => import('./public/login-ecotech/login-ecotech.module').then(m => m.LoginEcotechModule)
  }, {
    path: 'login-gerenciador',
    loadChildren: () => import('./public/login-gerenciador-risco/login-gerenciador-risco.module').then(m => m.LoginModule)
  }, {
    path: 'login-transportador',
    loadChildren: () => import('./public/login-transportador/login-transportador.module').then(m => m.LoginTransportadorModule)
  }, {
    path: 'modulo-cliente-gerenciador',
    loadChildren: () => import('./private/modulo-cliente-gerenciador-risco/modulo-cliente-gerenciador-risco.module').then(m => m.ModuloClienteGerenciadorRiscoModule)
  }, {
    path: 'modulo-cliente-transportador',
    loadChildren: () => import('./private/modulo-cliente-transportador/modulo-cliente-transportador.module').then(m => m.ModuloClienteTransportadorModule)
  }, {
    path: 'modulo-ecotech',
    loadChildren: () => import('./private/modulo-ecotech/modulo-ecotech.module').then(m => m.EcotechModule)
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
