<h1 mat-dialog-title>Configurações do Evento - {{this.data.titulo}}</h1>

<mat-dialog-content style="margin-top: 1em;">  
      <mat-card>
        <mat-card-content>
            <div class="grid">
              <div class="col-12">
                <mat-form-field class="form_monitoramento" appearance="outline">
                  <mat-label>Selecione a área:</mat-label>
                  <mat-select [(ngModel)]="codigoZonaRisco">
                    <mat-option *ngFor="let zona of zonaRiscoList" [value]="zona.codigo">
                      {{zona.codigo}} - {{zona.descricao}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Selecione a Tecnologia de Rastreio!</mat-error>
                </mat-form-field>
              </div>
                <div class="col-12 sm:col-12 md:col-12" *ngIf="showCampoProximidade == true">
                    <mat-form-field appearance="outline">
                        <mat-label>Proximidade máxima para cercas (em metros):</mat-label>
                        <input matInput type="number" [(ngModel)]="proximidadeMaxima">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
      </mat-card>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="cancelBtnClick()">Cancelar</button>
  <button mat-button cdkFocusInitial (click)="okBtnClick()">Ok</button>
</div>