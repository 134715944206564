import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AreaRiscoService } from 'src/app/private/area-risco/area-risco.service';
import { GrupoAreaRiscoModel } from 'src/app/private/area-risco/grupo-area-risco-model';
import { SnackbarService } from '../../service/snackbar.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-configuracoes-evento-area-risco',
  templateUrl: './configuracoes-evento-area-risco.component.html',
  styleUrls: ['./configuracoes-evento-area-risco.component.scss']
})
export class ConfiguracoesEventoAreaRiscoComponent {

  zonaRiscoList: GrupoAreaRiscoModel[] = [];
  proximidadeMaxima: number;
  codigoZonaRisco: string;
  showCampoProximidade: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<ConfiguracoesEventoAreaRiscoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private areaRiscoService: AreaRiscoService,
    private snabackService: SnackbarService
  ) {
    this.buscarGrupoZonaRisco();
    this.proximidadeMaxima = this.data.proximidadeMaxima;
    this.codigoZonaRisco = this.data.codigoZonaRisco;
    this.showCampoProximidade = this.data.showCampoProximidade;
  }
  buscarGrupoZonaRisco() {
    const page:PageEvent = new PageEvent();
    page.pageIndex = 0;
    page.pageSize = 10000;
    this.areaRiscoService.listar(page).subscribe({
      next:(res) => this.zonaRiscoList = res.content,
      error:(err) => this.snabackService.openSnackBar('Não foi possível recuperar as zonas de risco', 'Close', 'error-snackbar')
    });
  }
  okBtnClick() {
    this.dialogRef.close({proximidadeMaxima: this.proximidadeMaxima, codigoZonaRisco: this.codigoZonaRisco});
  }
  cancelBtnClick() {
    this.dialogRef.close(null);
  }
}