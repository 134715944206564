import { Component, Inject, Input } from '@angular/core';
import { Observable, debounceTime, distinctUntilChanged, filter, map, of, switchMap } from 'rxjs';
import { GrupoContatoModel } from 'src/app/private/grupo-contato/grupo-contato-model';
import { TemplateMensagemModel } from '../../model/template-mensagem-model';
import { NotificacaoMensagemModel } from '../../model/notificacao-mensagem-model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GrupoContatoService } from 'src/app/private/grupo-contato/grupo-contato.service';
import { TemplateMensagemWppService } from 'src/app/private/template-mensagem-wpp/template-mensagem-wpp.service';
import { GrupoContatoPesquisaModel } from '../../model/grupo-contato-pesquisa-model';
import { TemplateMensagemPesquisaModel } from '../../model/template-mensagem-pesquisa-model';
import { SpringPageModel } from '../../model/spring-page-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TipoNotificacaoEnum } from '../../enum/tipo-notificacao-enum';
import { SnackbarService } from '../../service/snackbar.service';
import { validateAllFormFields } from '../../helpers/functions';
import { TipoNotificacaoMensagemEnum} from '../../enum/tipo-notificacao-mensagem-oficial-enum';

@Component({
  selector: 'app-grupo-contato-template-form',
  templateUrl: './grupo-contato-template-form.component.html',
  styleUrls: ['./grupo-contato-template-form.component.scss']
})
export class GrupoContatoTemplateFormComponent {

  filteredGrupoContato$?: Observable<GrupoContatoModel[]>;
  filteredTemplate$?: Observable<TemplateMensagemModel[]>;

  formGroup: FormGroup;

  grupoContatoPesquisaModel: GrupoContatoPesquisaModel = new GrupoContatoPesquisaModel();
  templateMensagemPesquisaModel: TemplateMensagemPesquisaModel = new TemplateMensagemPesquisaModel();

  TipoNotificacaoOficialEnum = TipoNotificacaoMensagemEnum;

  constructor(
    private fb: FormBuilder,
    private grupoContatoService: GrupoContatoService,
    private templateMensagemWppService: TemplateMensagemWppService,
    private dialogRef: MatDialogRef<GrupoContatoTemplateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {notificacaoMensagemModel?: NotificacaoMensagemModel},
    private snackbarService: SnackbarService
  ) {

    this.formGroup = this.fb.group({
      'grupoContato' : new FormControl(this.data.notificacaoMensagemModel?.grupoContato, Validators.required),
      'templateMensagem' : new FormControl(this.data.notificacaoMensagemModel?.templateMensagem, Validators.required),
      'numeroAlertasParaNotificar' : new FormControl(this.data.notificacaoMensagemModel?.numeroAlertasParaNotificar, Validators.required),
      'tipoNotificacao' : new FormControl(this.data.notificacaoMensagemModel?.tipoNotificacao || TipoNotificacaoEnum.G, [Validators.required]),
      'isNotificacaoMensagemOficial': new FormControl(this.data.notificacaoMensagemModel?.isNotificacaoMensagemOficial || TipoNotificacaoMensagemEnum.O, Validators.required)

    });

    this.tipoNotificacaoChanged(this.formGroup.get('tipoNotificacao').value);
    this.setGrupoContatoAutocompleteListener();
    this.setTemplateAutocompleteListener();
  }

  setGrupoContatoAutocompleteListener(): void {
    this.filteredGrupoContato$ = this.formGroup.get('grupoContato')?.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(nomeGrupoContato => {
        if(typeof nomeGrupoContato === 'string') {
          this.grupoContatoPesquisaModel.nome = nomeGrupoContato.trim();
        }

        return typeof nomeGrupoContato === 'string' && nomeGrupoContato.trim().length >= 1
      }),
      switchMap(nomeGrupoContato => this.grupoContatoService.listarInformacoesBasicas(this.grupoContatoPesquisaModel)),
      map((res: SpringPageModel) => res && res.content ? res.content : [])
    )|| of([]);
  }

  setTemplateAutocompleteListener(): void {
    this.filteredTemplate$ = this.formGroup.get('templateMensagem')?.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(nomeTemplateMensagemWpp => {
        if(typeof nomeTemplateMensagemWpp === 'string') {
          this.templateMensagemPesquisaModel.nome = nomeTemplateMensagemWpp.trim();
        }
        return typeof nomeTemplateMensagemWpp === 'string' && nomeTemplateMensagemWpp.trim().length >= 1
      }),
      switchMap(nomeTemplateMensagemWpp => this.templateMensagemWppService.listar(this.templateMensagemPesquisaModel)),
      map((res: SpringPageModel) => res && res.content ? res.content : [])
    )|| of([]);
  }

  cancelar() {
    this.dialogRef.close();
  }

  okBtnClick() {
    if(!this.formGroup.valid) {
      validateAllFormFields(this.formGroup);
      this.snackbarService.openSnackBar('Por favor, preencha todos os campos antes de prosseguir.');
      return;
    }
    const grupoContatoTemplate: NotificacaoMensagemModel = new NotificacaoMensagemModel();
    Object.assign(grupoContatoTemplate, this.formGroup.value);
    console.log(grupoContatoTemplate);
    this.dialogRef.close(grupoContatoTemplate);
  }

  displayFnGrupoContato = (grupoContato: GrupoContatoModel): string => {
    return grupoContato && grupoContato.nome ? grupoContato.nome : '';
  }
  displayFnTemplate = (template: TemplateMensagemModel): string => {
    return template && template.nome ? template.nome : '';
  }

  tipoNotificacaoChanged(tipoNotificacaoSelecionada: string) {
    if(tipoNotificacaoSelecionada == TipoNotificacaoEnum.G) {
      this.formGroup.get('grupoContato').enable();
    } else if(tipoNotificacaoSelecionada == TipoNotificacaoEnum.M) {
      this.formGroup.get('grupoContato').setValue(null);
      this.formGroup.get('grupoContato').disable();
    }
  }

  tipoNotificacaoSelecionadoIsGrupo(): boolean {
    return this.formGroup.get('tipoNotificacao').value == TipoNotificacaoEnum.G;
  }

  tipoNotificacaoOficialChanged(isNotificacaoMensagemOficial: TipoNotificacaoMensagemEnum) {
    if (isNotificacaoMensagemOficial === TipoNotificacaoMensagemEnum.O) {
      this.formGroup.get('grupoContato')?.enable();
    } else if (isNotificacaoMensagemOficial === TipoNotificacaoMensagemEnum.N) {
      this.formGroup.get('grupoContato')?.disable();
      this.formGroup.get('grupoContato')?.setValue(null);
    }
  }
}
