import { LatLng } from "./latlng-model";

export class DadosEnderecoModel {
  cep: string;
  logradouro: string;
  bairro: string;
  numero: string;
  municipio: string;
  uf: string;
  pais: string;
  complemento: string;
  enderecoCompleto: string;
  latLng: LatLng;
}
