import { Component, Inject } from '@angular/core';
import { GoogleMapsGeocodeService } from '../../service/google-maps-geocode.service';
import { DadosEnderecoModel } from '../../model/dados-endereco-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../service/snackbar.service';
import { LoadingService } from '../../service/loading.service';

@Component({
  selector: 'app-detalhes-cep',
  templateUrl: './detalhes-cep.component.html',
  styleUrls: ['./detalhes-cep.component.scss']
})
export class DetalhesCepComponent {

  dadosEnderecoModel: DadosEnderecoModel = new DadosEnderecoModel();

  constructor(
    private googlemapsGeocodeService: GoogleMapsGeocodeService,
    private matDialogRef: MatDialogRef<DetalhesCepComponent>,
    private snackBarService: SnackbarService,
    private loadingService: LoadingService
  ) {
  }

  buscarCEP() {
    this.loadingService.showLoading(true, 'Buscando endereço');
    this.googlemapsGeocodeService.geocode(this.dadosEnderecoModel.cep).subscribe({
      next:(response) => {
        if(response && response.length > 0) {
          const local = response[0];

          this.setPais(local);
          this.setEstado(local);
          this.setMunicipio(local);
          this.setBairro(local);
          this.setLogradouro(local);

          this.dadosEnderecoModel.cep = this.dadosEnderecoModel.cep;
          this.dadosEnderecoModel.enderecoCompleto = local.formattedAddress;
          this.dadosEnderecoModel.latLng = local.geometry.location;
        } else {
          this.snackBarService.openSnackBar('Nenhum endereço encontrado para CEP informado.', 'Close', 'error-snackbar');
        }
        this.loadingService.showLoading(false);
      },
      error:(err) => {
        this.loadingService.showLoading(false);
        this.snackBarService.openSnackBar('Ocorreram erros ao busca o endereço. Por favor, tente novamente!', 'Close', 'error-snackbar');
        console.log(err);
      }
    });
  }

  adicionar() {
    if(!this.dadosEnderecoModel.latLng) {
      this.snackBarService.openSnackBar('Nenhum endereço selecionado!', 'Close', 'error-snackbar');
      return;
    }

    this.matDialogRef.close({dadosEnderecoModel: this.dadosEnderecoModel});
  }

  focus(event: any){
    this.dadosEnderecoModel = new DadosEnderecoModel();
  }

  setPais(local: any) {
    const pais = local.addressComponents.filter((ac: any) => ac.types.includes('COUNTRY'));
    this.dadosEnderecoModel.pais = pais[0] ? pais[0].longName : '';
  }
  setEstado(local: any) {
    const estado = local.addressComponents.filter((ac: any) => ac.types.includes('ADMINISTRATIVE_AREA_LEVEL_1'));
    this.dadosEnderecoModel.uf = estado[0] ? estado[0].longName : '';
  }
  setMunicipio(local: any) {
    const municipio = local.addressComponents.filter((ac: any) => ac.types.includes('ADMINISTRATIVE_AREA_LEVEL_2'));
    this.dadosEnderecoModel.municipio = municipio[0] ? municipio[0].longName : '';
  }
  setBairro(local: any) {
    const bairro = local.addressComponents.filter((ac: any) => ac.types.includes('SUBLOCALITY_LEVEL_1'));
    this.dadosEnderecoModel.bairro = bairro[0] ? bairro[0].longName : '';
  }
  setLogradouro(local: any) {
    const logradouro = local.addressComponents.filter((ac: any) => ac.types.includes('ROUTE'));
    this.dadosEnderecoModel.logradouro = logradouro[0] ? logradouro[0].longName : '';
  }
}
