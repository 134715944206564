import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppNavigatorService {

  constructor(private router: Router) { }

  login(): void {
    this.router.navigate(['/login']);
  }
  
  home(): void {
    this.router.navigate(['/home']);
  }
}
