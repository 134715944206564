import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RotaModel } from 'src/app/private/rota/rota-model';
import { AppGoogleMapsComponent } from '../app-google-maps/app-google-maps.component';
import { LocalizarVeiculoModel } from './localizar-veiculo-model';
import { LocalizarVeiculoService } from './localizar-veiculo.service';

@Component({
  selector: 'app-localizar-veiculo',
  templateUrl: './localizar-veiculo.component.html',
  styleUrls: ['./localizar-veiculo.component.scss']
})
export class LocalizarVeiculoComponent implements AfterViewInit {

  @ViewChild(AppGoogleMapsComponent) gmapsCmp: AppGoogleMapsComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {localizarVeiculoModel: LocalizarVeiculoModel, rota: RotaModel},
    private localizarVeiculoService: LocalizarVeiculoService
  ) {

  }

  ngAfterViewInit(): void {
    if(!this.data.localizarVeiculoModel.latitude || !this.data.localizarVeiculoModel.longitude) {
      this.localizarVeiculoService.localizarVeiculo(this.data.localizarVeiculoModel.placa, this.data.localizarVeiculoModel.veiculoRastreamentoId).subscribe({
        next: (res: LocalizarVeiculoModel) => {
          this.data.localizarVeiculoModel = res;
          this.addMarker(Number(this.data.localizarVeiculoModel.latitude), Number(this.data.localizarVeiculoModel.longitude));
        },
        error: (err: any) => console.log(err)
      });
    } else {
      this.addMarker(Number(this.data.localizarVeiculoModel.latitude), Number(this.data.localizarVeiculoModel.longitude));
    }
  }

  addMarker(lat: number, lng: number) {
    this.gmapsCmp.adicionarMarker({lat,lng});
  }


  getVertices() {
    if(!this.data.rota) return [];
    const v: google.maps.LatLngLiteral[] = [];
    this.data.rota.pontos.coordinates.forEach((element: any) => {
      v.push({
        lat: element.y,
        lng: element.x
      })
    });
    return v;
  }
}
