import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GrupoContatoPesquisaModel } from 'src/app/core/model/grupo-contato-pesquisa-model';
import { ParentService } from 'src/app/core/service/parent.service';
import { environment } from 'src/environments/environment';
import { GrupoContatoModel } from './grupo-contato-model';

@Injectable({
  providedIn: 'root'
})
export class GrupoContatoService extends ParentService {

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  listar(pesquisa: GrupoContatoPesquisaModel) {
    const baseUrl = `${environment.cadastro.grupoContato.pesquisar}`;
    const params = new HttpParams({fromObject: pesquisa as any});
    return super.get(baseUrl, params);
  }

  gravar(grupoContato: GrupoContatoModel){
    const url = `${environment.cadastro.grupoContato.gravar}`;
    return super.post(url, grupoContato);
  }

  alterarStatus(grupoContatoId: string) {
    const url = `${environment.cadastro.grupoContato.alterarStatus}?id=${grupoContatoId}`;
    return super.get(url);
  }

  listarInformacoesBasicas(pesquisa: GrupoContatoPesquisaModel) {
    const baseUrl = `${environment.cadastro.grupoContato.pesquisarInfoBasicas}`;
    const params = new HttpParams({fromObject: pesquisa as any});
    return super.get(baseUrl, params);
  }
}
